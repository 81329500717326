import * as THREE from "three";
import {CBARContext} from "./CBARContext";
import {CBARScene} from "./components/CBARScene";
import {CBARObject3D} from "./components/CBARObject3D";

export enum CBARMode {
    None,
    Video,
    Image,
}

export class CBARRenderContext {

    constructor(
        public camera:THREE.PerspectiveCamera,
        public scene:THREE.Scene,
        public renderer:THREE.WebGLRenderer,
        public renderTarget:THREE.WebGLRenderTarget,
        public resolution:THREE.Vector2
    ) {

    }
}

export enum CBAREventType {

    ContextMenu,
    Wheel,

    TouchDown,
    TouchMove,
    TouchUp,
    TouchLeave,

    Rotate,
    Translate,

    //computed
    DragStart,
    DragMove,
    DragEnd,

    MouseOver,
    MouseOut,
}

export enum CBARHighlightState {
    None = 'none',
    Hover = 'hover',
    Selected = 'selected',
    Drag = 'drag',
}

export type CBARIntersection = {
    intersection:THREE.Intersection
    object:CBARObject3D<any>
}

export enum CBARToolMode {
    None='',
    Rotate='rotate',
    Translate='translate',
    DrawSurface='draw',
    EraseSurface='erase'
}

export type CBAREvent = {
    type:CBAREventType,
    context:CBARContext,
    scene:CBARScene,
    toolMode:CBARToolMode
}

export type CBARMouseEvent = CBAREvent & {
    mouseEvent:any,
    point:THREE.Vector2
    intersections:CBARIntersection[]
}

export type CBAREventHandler = (event:CBAREvent)=>void

export type CBARVector = number[]

export enum CBARSurfaceType {
    Unknown = 'unknown',
    Floor = 'floor',
    Horizontal = 'horizontal',
    Wall = 'wall'
}

export enum CBARAssetType {
    Model = 'model',
    TiledSurface = 'tiled-surface',
    SurfaceModel = 'surface-model',

    PaintSurface = 'paint-surface',
    TiledRectangle = 'tiled-rectangle',

    Rug = 'tiled-rug',
}

export class CBMaterialProperties {

    constructor(public readonly ppi:number,
                public readonly diffuseUrl:string,
                public readonly normalsUrl?:string,
                public readonly specularUrl?:string) {

    }
}

export enum CBARServerFile {
    Background = "background",
    Lighting = "lighting",
    Superpixels = "superpixels",
    Mask = "mask",
}

export const CBARUploadNames = {
    Preview: "preview",
    Pinterest: "pinterest",
    Brand: "brand",
};

export type CBARSuperpixelData = {
    pixels: [number, number][]
    center: [number, number]
    meanRadius: number
}

export class CBARHistoryState {
    private readonly data:string;
    constructor(source:HTMLCanvasElement) {
        this.data = source.toDataURL("image/png")
        //console.log(`History is " ${Math.ceil(this.data.length / 1024)} kb`)
    }

    public restoreInto(source:HTMLCanvasElement, completed:()=>void) {
        const toCtx = source.getContext("2d");
        if (toCtx) {
            const img = new Image();
            img.addEventListener("load", function () {
                toCtx.drawImage(img, 0, 0);
                if (completed) completed();
            });
            img.setAttribute("src", this.data);
        }
    }
}