import {CBARImage} from "./CBARImage";
import * as THREE from "three";

export type SuperpixelData = {
    pixels: [number, number][]
    center: [number, number]
    meanRadius: number
}

export class CBARSuperpixelsImage {

    constructor(private image: CBARImage) {
        const data = this.loadSuperpixels();
        this.labels = data.labels;
        this.width = data.width;
        this.height = data.height;
    }

    private readonly labels: { [label: string]: SuperpixelData } = {};

    public width:number;
    public height:number;

    private loadSuperpixels() {
        const canvas = document.createElement("canvas");
        canvas.width = THREE.MathUtils.ceilPowerOfTwo(this.image.width);
        canvas.height = THREE.MathUtils.ceilPowerOfTwo(this.image.height);
        const ctx = canvas.getContext("2d");

        const labelData: { [label: string]: SuperpixelData } = {};

        if (ctx) {
            ctx.drawImage(this.image.image, 0, 0, canvas.width, canvas.height);

            const data = ctx.getImageData(0, 0, canvas.width, canvas.height).data;
            for (let x = 0; x < canvas.width; x++) {
                for (let y = 0; y < canvas.height; y++) {
                    const rgba = [
                        data[canvas.width * 4 * y + 4 * x],
                        data[canvas.width * 4 * y + 4 * x + 1],
                        data[canvas.width * 4 * y + 4 * x + 2],
                        data[canvas.width * 4 * y + 4 * x + 3],
                    ].toString();

                    const relPos: [number, number] = [
                        x / canvas.width,
                        y / canvas.height
                    ];

                    const labelDatum = labelData[rgba];
                    if (labelDatum) {
                        labelDatum.pixels.push(relPos);
                        labelDatum.center[0] += relPos[0];
                        labelDatum.center[1] += relPos[1]
                    } else {
                        labelData[rgba] = {
                            pixels: [relPos],
                            meanRadius: 0,
                            center: [relPos[0], relPos[1]]
                        }
                    }
                }
            }

            // Calculate centers and mean radii
            for (const label in labelData) {
                const labelDatum = labelData[label];
                labelDatum.center[0] /= labelDatum.pixels.length;
                labelDatum.center[1] /= labelDatum.pixels.length;

                for (const pixel of labelDatum.pixels) {
                    labelDatum.meanRadius += Math.sqrt(Math.pow(pixel[0] - labelDatum.center[0], 2) + Math.pow(pixel[1] - labelDatum.center[1], 2))
                }
                labelDatum.meanRadius /= labelDatum.pixels.length;
            }
        }

        return {labels:labelData, width:canvas.width, height:canvas.height}
    }

    public dataAtPoint(normalizePoint:THREE.Vector2) {
        const hitSuperpixelData: SuperpixelData[] = [];
        for (const label in this.labels) {
            const labelData = this.labels[label];
            const distance = Math.pow(labelData.center[0] - normalizePoint.x, 2) + Math.pow(labelData.center[1] - normalizePoint.y, 2);
            if (distance <= Math.pow(0.006 + labelData.meanRadius, 2)) {
                hitSuperpixelData.push(labelData);
            }
        }
        return hitSuperpixelData;
    }
}