import {Mat} from "mirada";
import * as gm from "gammacv";
import {CBARPipeline} from "./pipeline/CBARPipeline";

export class CBARFrame {
    constructor(public readonly context:CBARPipeline, public readonly index:number, public readonly time = performance.now()) {

    }

    get greyscaleOutput() {
        if (!this._hasGreyscale && this.context.session && this.context.greyscaleOp && this.context.greyscaleOutput) {
            this._hasGreyscale = true;
            this.context.session.runOp(this.context.greyscaleOp, this.index, this.context.greyscaleOutput);
        }
        return this.context.greyscaleOutput;
    }; private _hasGreyscale = false;

    get greyscaleImage() {
        if (!this._greyscaleImage && this.greyscaleOutput) {
            gm.canvasFromTensor(this.context.greyscaleCanvas, this.greyscaleOutput);
            this._greyscaleImage = cv.imread(this.context.greyscaleCanvas);
            cv.cvtColor(this._greyscaleImage, this._greyscaleImage, cv.COLOR_RGBA2GRAY, 0);
        }

        return this._greyscaleImage!;
    }; private _greyscaleImage?:Mat;

    get edgesOutput() {
        if (!this._hasEdges && this.context.session && this.context.edgesOp && this.context.edgesOutput) {
            this._hasEdges = true;
            this.context.session.runOp(this.context.edgesOp, this.index, this.context.edgesOutput);
        }
        return this.context.edgesOutput;
    }; private _hasEdges = false;

    get pcLinesOutput() {
        if (!this._hasPCLinesOutput && this.context.session && this.context.pcLinesOutput) {
            this._hasPCLinesOutput = true;
            this.context.session.runOp(this.context.pipeline as gm.Operation, this.index, this.context.pcLinesOutput);
        }
        return this.context.pcLinesOutput;
    }; private _hasPCLinesOutput = false;

    get colorSegmentationOutput() {
        if (!this._hasColorSegmentationOutput && this.context.session && this.context.pipeline && this.context.colorSegmentationOp && this.context.colorSegmentationOutput) {
            this._hasColorSegmentationOutput = true;
            this.context.session.runOp(this.context.colorSegmentationOp, this.index, this.context.colorSegmentationOutput);
        }
        return this.context.colorSegmentationOutput;
    }; private _hasColorSegmentationOutput = false;

    get colorSegmentationImage() {
        if (!this._colorSegmentationImage && this.colorSegmentationOutput && this.context.colorSegmentationCanvas) {
            gm.canvasFromTensor(this.context.colorSegmentationCanvas, this.colorSegmentationOutput);
            this._colorSegmentationImage = cv.imread(this.context.colorSegmentationCanvas);
            cv.cvtColor(this._colorSegmentationImage, this._colorSegmentationImage, cv.COLOR_RGBA2GRAY, 0);
        }
        return this._colorSegmentationImage!;
    }; private _colorSegmentationImage?:Mat;

    public destroy() {
        this._greyscaleImage?.delete();
        this._greyscaleImage = undefined;
    }

    public clone() {
        const copy = new CBARFrame(this.context, this.index, this.time);
        copy._greyscaleImage = this._greyscaleImage?.clone()
        return copy;
    }
}