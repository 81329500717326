import {CBARObject3D, CBARObject3DProperties} from "../components/CBARObject3D";
import * as THREE from "three";
import {CBARAssetType, CBAREvent, CBAREventType, CBARHighlightState, CBARMouseEvent} from "../CBARTypes";
import {DataItem} from "../data";

export interface CBARAssetProperties extends CBARObject3DProperties {

}

export abstract class CBARAsset extends CBARObject3D<CBARAsset> {
    private _rotation = new THREE.Euler(0,0,0);

    public get hoverColor() : THREE.Color | undefined {
        return new THREE.Color("white")
    }

    get rotation() : THREE.Euler {
        return this._rotation
    }

    set rotation(value:THREE.Euler) {
        this._rotation = value;
        this.renderObject.rotation.set(this._rotation.x,this._rotation.y, this._rotation.z);
        this.context.refresh();
    }

    get position() : THREE.Vector3 {
        return this.renderObject.position
    }

    set position(value:THREE.Vector3) {
        this.renderObject.position.set(value.x, value.y, value.z);
        this.context.refresh();
    }

    removeFromScene() : void {
        super.removeFromScene();
        this.context.scene?.assets.remove(this);
        this.context.refresh();
    }
}

export abstract class CBARTangibleAsset extends CBARAsset {

    public abstract get type() : CBARAssetType

    public handleEvent(event: CBAREvent) {
        super.handleEvent(event);

        switch (event.type) {


            case CBAREventType.TouchDown:
                event.scene.setAssetFor(CBARHighlightState.Drag, this, event);
                event.scene.setAssetFor(CBARHighlightState.Selected, this, event);
                break;

            case CBAREventType.TouchMove:

                break;

            case CBAREventType.TouchUp:
                if (this.getState(CBARHighlightState.Drag).active) {
                    event.scene.setAssetFor(CBARHighlightState.Drag, undefined, event);
                }
                break;

            case CBAREventType.MouseOver:
                event.scene.setAssetFor(CBARHighlightState.Hover, this, event);
                break;

            case CBAREventType.MouseOut:
                if (this.getState(CBARHighlightState.Selected).active) {
                    event.scene.setAssetFor(CBARHighlightState.Hover, undefined, event);
                }
                break;
        }

    }

    protected willAddToScene() : void {
        const obj = this.renderObject;
        if (!obj) return;

        obj.castShadow = true;
        obj.receiveShadow = true;

        super.willAddToScene();
    }

    private _product?:DataItem;

    public get product() {
        return this._product
    }

    public get canMove() {
        return true
    }

    public loadProduct(product:DataItem, properties:any) : Promise<CBARAsset> {
        this._product = product;
        if (product.name) {
            this.name = product.name;
        }
        return this.load(undefined, properties) as Promise<CBARAsset>
    }
}

