import * as THREE from "three";
import {getEnumValues} from "../CBARUtils";
import {Mat, Point} from "mirada";
import {Line} from "gammacv";

export type Size = {
    width:number
    height:number
}

const pattern = /#include <(.*)>/gm;

export function parseIncludes( string:any ){
    function replace( match:any , include:any ){
        const replace = THREE.ShaderChunk[ include ];
        return parseIncludes( replace )
    }
    return string.replace( pattern, replace )
}

export function confineToEnum<T>(en:any, values:T[]) : T[] {
    const available = getEnumValues(en);
    const restricted = values.filter(value=>available.indexOf(value) >= 0);
    return restricted as T[]
}

export function randomIntFromInterval(min:number, max:number) { // min and max inclusive
    return Math.floor(Math.random() * (max - min + 1) + min);
}

//https://stackoverflow.com/questions/9229645/remove-duplicate-values-from-js-array
export function uniq(a:any) {
    let seen:any = {};
    return a.filter(function(item:any) {
        return seen.hasOwnProperty(item) ? false : (seen[item] = true);
    });
}

export function normalizedToScreen(point2D:THREE.Vector2) : THREE.Vector2 {
    return new THREE.Vector2(point2D.x * 2.0 - 1.0, 1.0 - point2D.y * 2.0)
}

/**
 * @author Mikhail Zachepilo <mihailzachepilo@gmail.com>
 */

/**
 * @typedef {number} Ratio - number representation of ratio: width / height
 * @example 4 / 3 = 1.3333333333333333
 */

/**
 * @typedef {{width: number, height: number}} Size
 */

/**
 * @param {Ratio} ratio
 * @param {number} h
 */
export function getWidth(ratio: number, h: number): number {
    return ratio * h;
}

/**
 * @param {Ratio} ratio
 * @param {number} w
 */
export function getHeight(ratio: number, w: number): number {
    return w / ratio;
}

/**
 *
 * @param {Ratio} ratio
 * @param {number} maxWidth
 * @param {number} [maxHeight]
 * @return {Size}
 */
export function getMaxAvailableSize(ratio: number, maxWidth: number, maxHeight: number) {
    if (maxWidth) {
        const height = getHeight(ratio, maxWidth);

        if (height <= maxHeight) {
            return {
                height,
                width: maxWidth,
            };
        }
    }

    return {
        width: getWidth(ratio, maxHeight),
        height: maxHeight,
    };
}

/**
 *
 * @param {Ratio} ratio
 * @param {number} minWidth
 * @param {number} [minHeight]
 * @return {Size}
 */
export function getMinAvailableSize(ratio: number, minWidth: number, minHeight: number) {
    if (minWidth) {
        const height = getHeight(ratio, minWidth);

        if (height > minHeight) {
            return {
                height,
                width: minHeight,
            };
        }
    }

    return {
        width: getWidth(ratio, minHeight),
        height: minHeight,
    };
}

type TDeviceType = 'desktop' | 'tablet' | 'mobile';

export interface IDeviceInfo {
    type: TDeviceType
    height: number;
    width: number;
}

export type DeferredExecutor<T> = {
    resolve:(value: T | PromiseLike<T>) => void,
    reject:(reason: any) => void
}

export const getDeviceInfo = (): IDeviceInfo => {
    const { innerWidth, innerHeight } = window;
    let type: TDeviceType = 'desktop';

    if (innerWidth <= 1024 && innerWidth > 768 && innerHeight > 375) {
        type = 'tablet';
    } else if (innerWidth <= 768 || (innerWidth <= 812 && innerHeight <= 375)) {
        type = 'mobile';
    }

    return {
        type,
        width: innerWidth,
        height: innerHeight,
    };
};

export const shuffle = (array:any[]) => {
    let currentIndex = array.length,  randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }

    return array;
}

const CV_CN_SHIFT = 3;
const CV_DEPTH_MAX = (1 << CV_CN_SHIFT)
const CV_MAT_DEPTH_MASK = (CV_DEPTH_MAX - 1);

export const cvType2String = (type:number) => {
    let r:string;

    let depth = type & CV_MAT_DEPTH_MASK;
    let chans = 1 + (type >> CV_CN_SHIFT);

    switch ( depth ) {
        case cv.CV_8U:  r = "8U"; break;
        case cv.CV_8S:  r = "8S"; break;
        case cv.CV_16U: r = "16U"; break;
        case cv.CV_16S: r = "16S"; break;
        case cv.CV_32S: r = "32S"; break;
        case cv.CV_32F: r = "32F"; break;
        case cv.CV_64F: r = "64F"; break;
        default:     r = "User"; break;
    }

    r += "C";
    r += (chans);

    return r;
}

export const INT_MAX = 2147483647

export const toRadians = (degrees:number)=>{return degrees * Math.PI / 180; }

export const pointsToContour = (points:Point[]) => {
    const contour = new cv.Mat(points.length, 1, cv.CV_32SC2);
    for (let i=0; i<points.length; i++) {
        contour.data32S[i*2] = points[i].x;
        contour.data32S[i*2+1] = points[i].y;
    }
    return contour;
}

export const drawLines = (img:Mat, lines:Line[]) => {
    const contour = new cv.Mat();
    const cont = new cv.MatVector();
    cont.push_back(contour);

    cv.drawContours(img, cont, 0, [1, 0, 0, 0], cv.FILLED);
    cont.delete();
}

export class Bounds {
    constructor(public x1:number, public y1:number, public x2:number, public y2:number) {

    }

    public get width() {
        return this.x2 - this.x1;
    }

    public get height() {
        return this.y2 - this.y1;
    }

    public get area() {
        return this.width * this.height;
    }
}

export const getCanvasMat = (canvas:HTMLCanvasElement, bounds:Bounds) => {
    const sx = canvas.width * bounds.x1;
    const sy = canvas.height * bounds.y1;
    const sw = canvas.width * (bounds.x2 - bounds.x1);
    const sh = canvas.height * (bounds.y2 - bounds.y1);

    const context = canvas.getContext("2d");
    if (context) {
        return cv.matFromImageData(canvas.getContext("2d")!.getImageData(sx, sy, sw, sh));
    }
}

export const overlayMask = (_mask:Mat, rgb:Mat, value=0, hue=60) => {
    const img = new cv.Mat();
    let mask = new cv.Mat();
    if (_mask.depth() !== cv.CV_8U) {
        _mask.convertTo(mask, cv.CV_8U);
    } else {
        mask = _mask;
    }
    cv.cvtColor(rgb, img, cv.COLOR_RGB2HSV);

    const hsvChannels = new cv.MatVector();
    cv.split(img, hsvChannels);

    const hueChannel = hsvChannels.get(0);
    hueChannel.setTo([255,255,255,255], mask);
    hsvChannels.set(0, hueChannel);
    hueChannel.delete();

    const satChannel = hsvChannels.get(1);
    if (value) {
        satChannel.setTo([value,value,value,255], mask);
    } else {
        mask.copyTo(satChannel);
    }
    if (mask != _mask) {
        mask.delete();
    }
    hsvChannels.set(1, satChannel);
    satChannel.delete();

    cv.merge(hsvChannels, img);

    hsvChannels.delete();

    cv.cvtColor(img, rgb, cv.COLOR_HSV2RGB);
    img.delete();
}
