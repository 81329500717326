import {CBARCollection} from "../CBARCollection";
import {CBARObject3D, CBARObject3DProperties} from "./CBARObject3D";
import {
    CBAREvent,
    CBAREventType,
    CBARHighlightState,
    CBARHistoryState,
    CBARMouseEvent,
    CBARSurfaceType,
    CBARToolMode
} from "../CBARTypes";
import {CBARImageCollection, CBARImageDictionary} from "./CBARImageCollection";
import {CBARSurfaceAsset} from "../assets/CBARSurfaceAsset";
import {CBARContext, isDrawingTool} from "../CBARContext";

import {CBARMaterialProperty, CBARTextureType} from "./CBARMaterial";

import * as THREE from "three";
import {LineGeometry} from "three/examples/jsm/lines/LineGeometry";
import {LineMaterial} from "three/examples/jsm/lines/LineMaterial";
import {Line2} from "three/examples/jsm/lines/Line2";
import {getConfig} from "../../backend";
import {CBARImage} from "./CBARImage";
import {Bounds, getCanvasMat, normalizedToScreen, pointsToContour} from "../internal/Utils";
import * as internal from "../internal/Internal"
import {CBARSuperpixelsImage} from "./CBARSuperpixelsImage";
import {CBARMaskTexture} from "./CBARMaskTexture";
import {CBARStandardMaterial} from "./CBARStandardMaterial";
import {Mat, Point} from "mirada";
import {CBARImaging, Labels} from "../internal/CBARImaging";
import {ErrorLog} from "../internal/GlobalLogger";
import {euclideanDist, euclideanDistSq} from "../internal/Math";
import {Line} from "gammacv";
import {angleBetweenLines, isOnEdge, lineBisector} from "../internal/Line";

const colors = ['aqua', 'blue', 'red', 'green', 'lime', 'maroon', 'navy', 'olive',
    'purple', 'fuchsia', 'silver', 'teal', 'white', 'yellow', 'orange'];
let colorIndex = 0;

type DrawingPoint = {
    point:Point
    radius:number
    time:number
    drawn:boolean
}

const DEFAULT_DISTANCE_METERS = 4.0;
const DRAW_RADIUS_METERS = 0.03;
const DRAW_MAX_DISTANCE_METERS = 10;
export let DRAW_RADIUS = DRAW_RADIUS_METERS / DEFAULT_DISTANCE_METERS

const DRAWING_POINT_REFRESH = 200;
const DRAWING_POINT_TIMEOUT = 1000;

const REFINE_EPSILON = 5;

export interface CBARSurfaceProperties extends CBARObject3DProperties {
    type?:CBARSurfaceType
    color?:string
    extent?:number[],
    normal?:number[],
    offset?:number,
    images:CBARImageDictionary,
    contours?:any[]
}

const APPROX_POLY = true;
const POLY_EPSILON = 1.0;
const MAX_PROJECTED_DISTANCE = 100.0;
const MIN_CONTOUR_AREA = 20 * 20;

export class CBARSurface extends CBARObject3D<CBARSurface> implements CBARCollection<CBARSurfaceAsset> {

    _generatedColor = new THREE.Color(colors[(colorIndex ++) % colors.length]);

    public axisRotation = 0.0;

    public constructor(context:CBARContext, public index:number) {
        super(context);

        this.material = new THREE.MeshLambertMaterial({color:this._generatedColor, transparent: true, opacity: Math.min(this.baseOpacity, getConfig().hoverBGOpacity), depthTest:false});
        this.setRenderObject(this._container);
    }

    private get hasPlaceholder() {
        return this.type === CBARSurfaceType.Floor && !!this.context.scene?.placeholderTexture && this.length() === 0;
    }

    public needsUpdate() {

        this._placeholderMaterial?.setMaterialProperty(CBARMaterialProperty.opacity, this.hasPlaceholder ? 1.0 : 0.0001);

        // const config = getConfig();
        // let opacity = 0.0;
        // if (this.selected && !this.length() && !this.context.isDrawing) {
        //     opacity = Math.max(opacity, config.selectedOutlineOpacity);
        // }
        // if (this.getState(CBARHighlightState.Hover).active) {
        //     opacity = Math.max(opacity, config.hoverOutlineOpacity);
        // }
        //
        // for (const outline of this._outlines) {
        //     const material = outline.material as THREE.Material;
        //     if (!material) break;
        //     outline.visible = opacity > 0.0
        // }
        //
        // if (this.selected && this.context.isDrawing) {
        //     this.material.opacity = this.length() ? this.baseOpacity : 0.4;
        // }
        //
        // if (this.getState(CBARHighlightState.Hover).active) {
        //     this.material.opacity = Math.max(this.material.opacity, config.hoverBGOpacity);
        // }
    }

    private _container = new THREE.Group();
    public baseOpacity = 0.0;
    public material:THREE.MeshLambertMaterial;

    protected images = new CBARImageCollection(this.context);

    private _assets: { [id: string]: CBARSurfaceAsset} = {};

    public get selectedColor() {
        return this.color
    }

    public get values() : { [id: string] : CBARSurfaceAsset; } {
        return this._assets
    }

    public first() {
        const length = this.length();
        if (length) {
            return this.all()[0];
        }
    }

    public last() {
        const length = this.length();
        if (length) {
            return this.all()[length-1];
        }
    }

    public all() {
        return Object.values(this._assets)
    }

    public get sorted() : CBARSurfaceAsset[] {
        return Object.values(this._assets).sort((a, b) => a.surfaceElevation - b.surfaceElevation)
    }

    private sortAssets() {
        const assets = this.sorted;//why is this changing?
        assets.forEach(asset=>{
            this.renderObject.remove(asset.renderObject);
        });
        assets.forEach(asset=>{
            this.renderObject.add(asset.renderObject);
        });

        this.needsUpdate();
    }

    public add(asset:CBARSurfaceAsset, elevation=0.0) {

        asset.surface = this;

        if (this.type === CBARSurfaceType.Floor) {
            //place in front of us on the floor, otherwise (default) the center of the surface
            const maskCenter = new THREE.Vector2(0.5,0.9);//hard coded center of floor in front and down.
            const center = this.screenToSurfacePosition(maskCenter);
            asset.setSurfacePosition(center.x, center.y);
        }

        asset.surfaceElevation = elevation;
        this._assets[asset.id] = asset;

        this.context.scene?.assets.add(asset);
        asset.addedToSurface(this);

        this.sortAssets();
    }

    public containsKey(key:string) : boolean {
        return this._assets.hasOwnProperty(key);
    }

    public remove(asset:CBARSurfaceAsset) {
        if (!this.containsKey(asset.id) || asset.surface !== this) return;
        this.context.scene?.assets.remove(asset);
        this.needsUpdate();
    }

    //internal: called from CBARSurfaceAsset.removeFromScene()
    private internal_removeKey(key:string) {
        delete this._assets[key]
        this.needsUpdate();
    }

    public length(): number {
        return Object.keys(this._assets).length
    }

    private _extent = new THREE.Vector2();

    public get extent() : THREE.Vector2 {
        return this._extent
    }

    public type = CBARSurfaceType.Unknown;

    private _planeNormal?:THREE.Vector3;
    public get planeNormal() {
        return this._planeNormal
    }

    private _planeOffset?: number;
    public get planeOffset() {
        return this._planeOffset
    }

    private _raycaster = new THREE.Raycaster();

    public get color() {
        return this.material.color
    }

    public set color(value) {
        this.material.color = value
    }

    public maskTexture?:CBARMaskTexture;

    public plane = new THREE.Plane();

    private _outlines:Line2[] = [];
    private _shapeMeshes:THREE.Mesh[] = [];
    private _placeholderMeshes:THREE.Mesh[] = [];
    private _placeholderMaterial?:CBARStandardMaterial;

    load(basePath:string|undefined, json:CBARSurfaceProperties, room?:string|null|undefined, subroom?:string|null|undefined) : Promise<CBARSurface> {

        if (json.type ) {
            this.type = json.type
        }

        if (json.color) {
            this.color = new THREE.Color(parseInt(json.color, 16))
        }

        if (json.normal) {
            this._planeNormal = new THREE.Vector3(-json.normal[0], -json.normal[2], json.normal[1])
        }

        this._planeOffset = json.offset ? json.offset : Number.EPSILON;

        const promises:any[] = [];

        promises.push(this.images.load(basePath, json.images, room, subroom));

        //const maskSize:Size = {width:640, height:480};

        return new Promise<CBARSurface>((resolve, reject) => {
            super.load(basePath, json).then(()=>{
                Promise.all(promises).then(()=>{

                    if (this._planeOffset && this._planeNormal) {

                        this.plane = new THREE.Plane(this._planeNormal, this._planeOffset);
                        this.extent.x = 0.0;
                        this.extent.y = 0.0;
                    }

                    if (this.maskImage && this.maskImage.area) {
                        this.maskTexture = new CBARMaskTexture(this.context, CBARTextureType.alpha);
                        this.maskTexture.loadImage(this.maskImage);
                    }

                    resolve(this)
                }).catch(error=>{
                    reject(error)
                })
            }).catch(error=>{
                reject(error)
            })
        })
    }

    public get maskImage() : CBARImage | undefined {
        if (this.images.containsKey("mask")) {
            return this.images.values['mask'] as CBARImage;
        }
    }

    get description() : string {
        return `${this.type} Surface ${this.index}`
    }

    public data() : any {
        const data:any = super.data();

        data.extent = [this.extent.x, this.extent.y];

        data.color = this.color.getHexString();

        return data
    }

    private getSuperpixelsImage() : CBARSuperpixelsImage | undefined {
        return (<internal.CBARScene><any>this.context.scene)?.superpixelsImage;
    }

    private drawingMaybeChanged(toolMode:CBARToolMode) {

        if (!this.maskTexture) {
            console.log("No mask texture");
            return;
        }

        if (this.maskTexture.isEditing) {
            if  (!this.isDrawingOn || !this.selected || !isDrawingTool(toolMode)) {
                this.stoppedEditing();
            }
        } else {
            if (this.selected && this.isDrawingOn && !this.maskTexture.isEditing) {
                this.startedEditing();
            }
        }
    }

    private _drawingPoints:DrawingPoint[] = [];
    private _drawingPointsAreErase = false;

    private drawEraseAtPoint(point: THREE.Vector2) {
        this.drawingMaybeChanged(this.context.toolMode);

        const wasErase = this._drawingPointsAreErase;
        this._drawingPointsAreErase = this.context.toolMode === CBARToolMode.EraseSurface;
        if (wasErase !== this._drawingPointsAreErase) {
            this.clearPoints()
        }

        this._drawingPoints.push({point:point, radius:DRAW_RADIUS, time:performance.now(), drawn:false});
    }

    private _setClear = false;
    private clearPoints() {
        this._setClear = true;
    }

    private _lastDraw = 0;

    private startedEditing() {
        if (this.maskTexture) {
            this.maskTexture.isEditing = true;
        }

        this._commitTimer = window.setInterval(()=>{
            const maskTexture = this.maskTexture;
            const drawingCanvas = this.maskTexture?.canvas;
            const background = (this.context.gl.scene.background as THREE.Texture).image;
            //const background = this.context.scene?.lightingTexture?.canvas;

            if (!maskTexture || !drawingCanvas || !background) return;

            const min_age = (this._lastDraw ? this._lastDraw : performance.now()) - DRAWING_POINT_TIMEOUT;
            const pointsFiltered = this._drawingPoints.filter(p=>p.time > min_age || !p.drawn);

            if ((this._setClear || pointsFiltered.length) && !this._busy) {
                this._busy = true;
                this._lastDraw = performance.now();

                if (this._setClear) {
                    this._setClear = false;
                    this._drawingPoints = [];
                }

                //set to drawn
                pointsFiltered.forEach(p=>p.drawn = true);

                const points = [...pointsFiltered];
                //const start = performance.now();
                this.commitDrawingChanges(this._drawingPointsAreErase, points, drawingCanvas, background).then(()=>{
                    this._busy = false;
                    maskTexture.showChanges(drawingCanvas);
                }).catch(error=>{
                    //showDebugText(error.hasOwnProperty("message") ? error.message : error);
                    ErrorLog(error);
                })
            }

        }, DRAWING_POINT_REFRESH);
    }

    private stoppedEditing() {
        //showDebugText("stopped editing");

        if (this._commitTimer) clearInterval(this._commitTimer);

        if (this.maskTexture) {
            this.maskTexture.isEditing = false;
        }
        this.clearHistory();
        this.regenerateMeshes(false);
        this.context.refresh();
        this.needsUpdate();
    }

    private _commitTimer = 0;
    private _busy=false;

    private calculateBounds = (normalizedPoints:DrawingPoint[], padding:number)=>{
        const boundsNormalized = new Bounds(1,1,0,0);

        normalizedPoints.forEach((dp)=>{
            const p = dp.point;
            boundsNormalized.x1 = Math.min(boundsNormalized.x1, p.x);
            boundsNormalized.x2 = Math.max(boundsNormalized.x2, p.x);

            boundsNormalized.y1 = Math.min(boundsNormalized.y1, p.y);
            boundsNormalized.y2 = Math.max(boundsNormalized.y2, p.y);
        });

        boundsNormalized.x1 = Math.max(0, boundsNormalized.x1-padding);
        boundsNormalized.x2 = Math.min(boundsNormalized.x2+padding, 1);
        boundsNormalized.y1 = Math.max(0, boundsNormalized.y1-padding);
        boundsNormalized.y2 = Math.min(boundsNormalized.y2+padding, 1);

        return boundsNormalized;
    }

    private async commitDrawingChanges(isErase:boolean, normalizedPoints:DrawingPoint[], maskCanvas:HTMLCanvasElement, backgroundCanvas:HTMLCanvasElement) {

        const uncertainExpand = isErase ? 1.5 : 2.0;
        const padding = normalizedPoints[normalizedPoints.length - 1].radius * uncertainExpand * 1.1;
        const boundsNormalized = this.calculateBounds(normalizedPoints, padding);

        if (!boundsNormalized.area) return;

        const imageDiagonal = Math.hypot(maskCanvas.height, maskCanvas.width);

        //prepare images
        const mask = getCanvasMat(maskCanvas, boundsNormalized);
        if (!mask) return;
        cv.cvtColor(mask, mask, cv.COLOR_RGBA2GRAY);

        const background = getCanvasMat(backgroundCanvas, boundsNormalized);
        if (!background) return;
        cv.cvtColor(background, background, cv.COLOR_RGBA2RGB);

        //Smooth the bg, preserving edges:
        const scratch = new cv.Mat(background.rows, background.cols, background.depth());
        cv.bilateralFilter(background, scratch, 13, 80, 80, cv.BORDER_REFLECT);
        scratch.copyTo(background);
        scratch.delete();
        //imShow(background);

        //const offscreen = new OffscreenCanvas(background.cols, background.rows);

        if (background.cols != mask.cols || background.rows != mask.rows) {
            cv.resize(background, background, {width:mask.cols, height:mask.rows});
        }

        const factor = mask.rows / boundsNormalized.height;

        const denormalizedPoints:DrawingPoint[] = normalizedPoints.map((dp)=>{
            const p = dp.point;
            return {
                point:{x:(p.x - boundsNormalized.x1) * factor, y:(p.y - boundsNormalized.y1) * factor},
                radius:dp.radius * factor,
                time:dp.time,
                drawn:dp.drawn
            }
        });

        const boundsDiagonal = 2.0 * factor * Math.max(boundsNormalized.width - 2 * padding, boundsNormalized.height - 2 * padding);

        const radiusFactor = 0.75;
        const first = denormalizedPoints[0];
        const uncertainPoints = denormalizedPoints.map((p)=>{
            return {...p, distance:euclideanDist(p.point, first.point)}
        }).filter(p=>p.distance > Math.min(p.radius, boundsDiagonal - p.radius)).map(p=>{
            //const factor = 1 + p.distance / Math.pow(p.radius,  0.8);
            //const _uncertainExpand = 1 + (uncertainExpand -1) / factor;
            return {...p, radius:p.radius * uncertainExpand};
        });

        //build markers
        const markers = cv.Mat.zeros(mask.rows, mask.cols, cv.CV_32S);
        markers.setTo(CBARImaging.nonMaskLabel);
        markers.setTo(CBARImaging.maskLabel, mask);

        if (!isErase) {
            uncertainPoints.forEach(dp=>cv.circle(markers, dp.point, dp.radius, CBARImaging.uncertainLabel, cv.FILLED));
        }

        //const kernel = cv.getStructuringElement(cv.MORPH_CROSS, {width:5, height:5});

        const distance = 0.93;
        const altered = new cv.Mat();
        cv.distanceTransform(mask, altered, cv.DIST_L2, cv.DIST_MASK_5);
        cv.normalize(altered, altered, 255.0, 0, cv.NORM_MINMAX);
        cv.threshold(altered, altered,  255.0 - 255.0 * distance, 255, cv.THRESH_BINARY);
        altered.convertTo(altered, cv.CV_8U, 1, 0);
        markers.setTo(CBARImaging.maskLabel, altered);
        altered.delete();

        if (isErase) {
            uncertainPoints.forEach(dp=>cv.circle(markers, dp.point, dp.radius, CBARImaging.uncertainLabel, cv.FILLED));
            denormalizedPoints.forEach(dp=>cv.circle(markers, dp.point, dp.radius * radiusFactor, CBARImaging.nonMaskLabel, cv.FILLED));
        } else {
            denormalizedPoints.forEach(dp=>cv.circle(markers, dp.point, dp.radius * radiusFactor, CBARImaging.maskLabel, cv.FILLED));
        }

        //imShowOverlay(background, markers);

        cv.watershed(background, markers);
        markers.convertTo(markers, cv.CV_8U);
        cv.threshold(markers, mask, Labels.nonMask+1, 255, cv.THRESH_BINARY);

        //snap to long edges
        if (REFINE_EPSILON) {
            //     const overlay = new cv.Mat();
            //     markers.convertTo(overlay, cv.CV_8U);
            //     imShowOverlay(background, overlay);
            //     overlay.delete();

            this.refineMask(mask, imageDiagonal);
        }

        const pad = (REFINE_EPSILON ? REFINE_EPSILON * Math.sqrt(2) : 1);

        //imShow(mask);
        const maskContext = maskCanvas.getContext("2d");
        if (maskContext) {
            const dx = maskCanvas.width * boundsNormalized.x1 + pad;
            const dy = maskCanvas.height * boundsNormalized.y1 + pad;

            cv.cvtColor(mask.roi({x:pad,y:pad,width:mask.cols-2*pad, height:mask.rows-2*pad}), mask, cv.COLOR_GRAY2RGBA);
            const array = new Uint8ClampedArray(mask.data, mask.cols, mask.rows);

            const imageData = maskContext.createImageData(mask.cols, mask.rows);
            imageData.data.set(array);

            maskContext.putImageData(imageData, dx, dy);
            maskContext.save();
        }

        background.delete();
        markers.delete();
        mask.delete();
    }

    private refineMask(mask:Mat, imageDiagonal=Math.hypot(mask.rows, mask.cols), debug?:Mat, color=[255,255,0,255]) {
        const contours = new cv.MatVector();
        const hierarchy = new cv.Mat();

        cv.findContours(mask, contours, hierarchy, cv.RETR_EXTERNAL, cv.CHAIN_APPROX_NONE);

        const contoursLength = (contours.size() as any) as number;
        const minLengthSq = (imageDiagonal / 30) ** 2;

        const range = REFINE_EPSILON * 2;
        const paddedRoi = new cv.Rect(REFINE_EPSILON, range, mask.cols-2*range, mask.rows-2*range);

        for (let i = 0; i < contoursLength; i++) {
            let poly = new cv.Mat();
            const contour = contours.get(i);
            cv.approxPolyDP(contour, poly, REFINE_EPSILON, false);

            let refinedLastPoint = false;
            let lastLine:Line|undefined;
            const angleMatchThreshold =  Math.PI / 8.0;

            for (let j = 0; j < poly.data32S.length; j+=2) {
                const point = new cv.Point(poly.data32S[j], poly.data32S[j+1]);
                const nextIndex = (j+2) % poly.data32S.length;
                const nextPoint = new cv.Point(poly.data32S[nextIndex], poly.data32S[nextIndex+1]);
                const lengthSq = euclideanDistSq(point, nextPoint);
                const line = new Line(point.x, point.y, nextPoint.x, nextPoint.y);

                if (!isOnEdge(line, paddedRoi) && (lengthSq >= minLengthSq || (refinedLastPoint && lastLine && angleBetweenLines(line, lastLine) <= angleMatchThreshold))) { //then erase area

                    refinedLastPoint = true;
                    lastLine = line;

                    //find perpendicular line
                    const insideOutside = lineBisector(line, Math.sqrt(2));
                    const isInside1 = mask.data[insideOutside.y1 * insideOutside.x1] === 0;

                    const bisector = lineBisector(line, range);
                    const insidePoint = isInside1 ? {x:bisector.x1, y:bisector.y1} : {x:bisector.x2, y:bisector.y2};
                    const outsidePoint = isInside1 ? {x:bisector.x2, y:bisector.y2} : {x:bisector.x1, y:bisector.y1};

                    const maskPoints:Point[] = [];
                    maskPoints.push({x:line.x1, y:line.y1});
                    maskPoints.push({x:line.x2, y:line.y2});

                    //erase outside
                    const outsideCover = new cv.MatVector();
                    outsideCover.push_back(pointsToContour([...maskPoints, outsidePoint, maskPoints[0]]));
                    cv.drawContours(mask, outsideCover, 0, [0,0,0,0], cv.FILLED);

                    //draw inside
                    const insideCover = new cv.MatVector();
                    insideCover.push_back(pointsToContour([...maskPoints, insidePoint, maskPoints[0]]));
                    cv.drawContours(mask, insideCover, 0, [255,255,255,255], cv.FILLED);

                    if (debug) {
                        cv.drawContours(debug, outsideCover, 0, [255,0,0,127], cv.FILLED);
                        cv.drawContours(debug, insideCover, 0, [255,255,0,127], cv.FILLED);
                        //cv.line(debug, {x:line.x1 + offset.x, y:line.y1 + offset.y}, {x:line.x2 + offset.x, y:line.y2 + offset.y}, [0,0,255,255], 1);
                        //cv.circle(debug, midpoint, 5, [255,255,255,255])
                        cv.circle(debug,{x:bisector.x1, y:bisector.y1}, isInside1 ? 3 : 1, [255,255,0,255])
                        cv.circle(debug,{x:bisector.x2, y:bisector.y2}, isInside1 ? 1 : 3, [0,255,255,255])
                    }

                    outsideCover.delete();
                    //insideCover.delete();
                }
                else {
                    refinedLastPoint = false;
                }
            }

            if (debug) {
                const mat = new cv.MatVector(); mat.push_back(poly);
                cv.drawContours(debug, mat, 0, color);
                mat.delete();
            }
            poly.delete();
        }

        contours.delete();
        hierarchy.delete();
    }

    protected undoHistory:CBARHistoryState[] = [];

    protected saveState() {
        if (this.maskTexture && this.maskTexture.canvas) {
            this.undoHistory.push(new CBARHistoryState(this.maskTexture.canvas));
        }
    }

    protected restoreState(state:CBARHistoryState, completed?:(success:boolean)=>void) {
        if (this.maskTexture && this.maskTexture.canvas) {
            const tex = this.maskTexture;
            state.restoreInto(this.maskTexture.canvas, ()=>{
                this.regenerateMeshes(false);
                tex.refresh();
                this.context.refresh();
                if (completed) completed(true);
            });
        } else if (completed) {
            completed(false);
        }
    }

    public get historyLength() {
        return this.undoHistory.length;
    }

    public undoLast(completed?:(success:boolean)=>void) {
        const state = this.undoHistory.pop();
        if (state) {
            this.restoreState(state, completed);
        } else {
            console.warn("Nothing to undo");
            if (completed) completed(false);
        }
    }

    public clearHistory() {
        this.undoHistory = [];
    }

    public revertChanges(completed?:(success:boolean)=>void) {
        if (this.undoHistory.length) {
            this.restoreState(this.undoHistory[0], (success:boolean)=>{
                this.clearHistory();
                if (completed) completed(success);
            });
        } else {
            //console.warn("Nothing to revert");
            if (completed) completed(false);
        }
    }

    public commitChanges(completed?:(success:boolean)=>void) {
        if (this.historyLength) {
            this.clearHistory();
            if (completed) completed(true);
        } else {
            console.warn("Nothing to commit");
            if (completed) completed(false);
        }
    }

    public get selected() {
        return this.context.scene?.surfaceFor(CBARHighlightState.Selected) === this;
    }

    public set selected(value:boolean) {
        if (this.context.scene) {
            if (value) {
                this.context.scene.setSurfaceFor(CBARHighlightState.Selected, this);
            } else {
                const selectedSurface = this.context.scene.surfaceFor(CBARHighlightState.Selected);
                if (selectedSurface === this) {
                    this.context.scene.setSurfaceFor(CBARHighlightState.Selected, undefined);
                }
            }
        } else {
            console.warn("selected: No scene");
        }
    }

    public get isDrawingOn() {
        return isDrawingTool(this.context.toolMode) && this.selected
    }

    public handleEvent(event: CBAREvent) {
        super.handleEvent(event);

        const me = event as CBARMouseEvent;

        //update drawing size if necessary:
        const intersection = isDrawingTool(this.context.toolMode) && me.point ? this.getPlaneIntersection(me.point) : undefined;

        if (intersection) {
            const distance = Math.min(DEFAULT_DISTANCE_METERS * 4, intersection.distanceTo(this.context.gl.camera.position));

            const selectedSurface = this.context.scene?.surfaceFor(CBARHighlightState.Selected);
            if (!selectedSurface || selectedSurface === this) {
                //const aspectRatio = this.context.gl.resolution.x / this.context.gl.resolution.y;
                DRAW_RADIUS = DRAW_RADIUS_METERS / Math.min(distance, DRAW_MAX_DISTANCE_METERS);
            }
        }

        switch (event.type) {

            case CBAREventType.TouchDown:
                if (!this.selected || this.context.toolMode === CBARToolMode.None
                    || (isDrawingTool(this.context.toolMode) && !this.context.scene?.surfaceFor(CBARHighlightState.Selected))) {
                    this.selected = true;
                }

                if (this.isDrawingOn) {
                    this.saveState();
                    this.drawEraseAtPoint(me.point);
                }
                break;

            case CBAREventType.TouchUp:
                if (this.isDrawingOn) {
                    this.drawEraseAtPoint(me.point);
                }
                this.clearPoints();

                break;

            case CBAREventType.DragMove:
                if (this.isDrawingOn) {
                    this.drawEraseAtPoint(me.point);
                }
                break;
        }
    }

    public clearAll() {
        const assets = this.values;

        for (let key in assets) {
            this.remove(assets[key])
        }
    }

    public existsAtPoint(coords:THREE.Vector2) : boolean {
        if (!this.maskTexture || !this.maskTexture.canvas) return false;

        if (this.isDrawingOn || (isDrawingTool(this.context.toolMode) && !this.context.scene?.surfaceFor(CBARHighlightState.Selected))) {
            return true;
        }

        const ctx = this.maskTexture.canvas.getContext("2d");

        if (!ctx) return false;

        const xPos = coords.x * this.maskTexture.canvas.width;
        const yPos = coords.y * this.maskTexture.canvas.height;

        const pixel = ctx.getImageData(xPos, yPos, 1, 1);

        return pixel.data.length > 0 && pixel.data[0] > 128;
    }

    protected getPlaneIntersection(point2D: THREE.Vector2) {
        const screenPoint = normalizedToScreen(point2D);
        this._raycaster.setFromCamera(screenPoint, this.context.gl.camera);
        return this._raycaster.ray.intersectPlane(this.plane, new THREE.Vector3());
    }

    public screenToSurfacePosition(point2D:THREE.Vector2) : THREE.Vector2 {
        const point3D = this.getPlaneIntersection(point2D);
        return point3D ? this.getSurfacePosition(point3D) : new THREE.Vector2();
    }

    private _pointTransform = new THREE.Matrix4().identity();

    public getSurfacePosition(point3d:THREE.Vector3) : THREE.Vector2 {
        const point4D = new THREE.Vector4(point3d.x, point3d.y, point3d.z, 1.0).applyMatrix4(this._pointTransform);
        return new THREE.Vector2(point4D.x, point4D.y);
    }

    private removeMeshes() {

        this._outlines.forEach(m=>this._container.remove(m));
        this._outlines = [];

        this._shapeMeshes.forEach(m=>this._container.remove(m));
        this._shapeMeshes = [];

        this._placeholderMeshes.forEach(m=>this._container.remove(m));
        this._placeholderMeshes = [];
    }

    private findContours(maskImage:HTMLCanvasElement, calculateTransform:boolean) {
        if (!maskImage) return;
        const mask = cv.imread(maskImage);

        if (mask.channels() == 4) {
            cv.cvtColor(mask, mask, cv.COLOR_RGBA2GRAY, 0)
        } else if (mask.channels()==3) {
            cv.cvtColor(mask, mask, cv.COLOR_RGB2GRAY, 0)
        }

        const contours = new cv.MatVector();
        const hierarchy = new cv.Mat();
        cv.findContours(mask, contours, hierarchy, cv.RETR_EXTERNAL, cv.CHAIN_APPROX_SIMPLE);
        const contoursLength = (contours.size() as any) as number; //hack: typedefs are wrong inside mirada

        const size = mask.size();
        const scale = 200.0 / Math.max(size.width, size.height);
        let expSize = new cv.Size(scale * size.width, scale * size.height);
        if (scale < 1.0){
            cv.resize(mask, mask, expSize);
        } else {
            expSize = size;
        }
        const expandedContours = new cv.MatVector();
        cv.findContours(mask, expandedContours, hierarchy, cv.RETR_EXTERNAL, cv.CHAIN_APPROX_SIMPLE);
        hierarchy.delete();

        if (this.context.scene?.cvLightingSmall && this.context.scene?.cvBackgroundSmall) {

            cv.resize(mask, mask, new cv.Size(this.context.scene.cvBackgroundSmall.cols, this.context.scene.cvBackgroundSmall.rows), 0,0, cv.INTER_NEAREST);

            let insideM = new cv.Mat();
            let insideS = new cv.Mat();
            cv.meanStdDev(this.context.scene.cvBackgroundSmall, insideM, insideS, mask);

            this.backgroundMean = insideM.data64F[1];//green channel
            this.backgroundStdDev = insideS.data64F[1];

            cv.meanStdDev(this.context.scene.cvLightingSmall, insideM, insideS, mask);

            this.lightingMean = insideM.data64F[0];//greyscale
            this.lightingStdDev = insideS.data64F[0];

            insideM.delete();
            insideS.delete();

            //console.log("backgroundMean", this.backgroundMean, "lightingMean", this.lightingMean);
        }

        mask.delete();

        const threeContours: THREE.Vector2[][] = [];
        const threeHulls: THREE.Vector2[][] = [];

        for (let i = 0; i < contoursLength; i++) {
            const contour = contours.get(i);

            let poly = new cv.Mat();
            if (APPROX_POLY) {
                cv.approxPolyDP(contour, poly, POLY_EPSILON, false)
            } else {
                poly = contour
            }

            const area = cv.contourArea(poly);
            if (area < MIN_CONTOUR_AREA) continue;

            const threeContour:THREE.Vector2[] = [];
            for (let j = 0; j < poly.data32S.length; j+=2) {
                const point = new THREE.Vector2(poly.data32S[j]/maskImage.width, poly.data32S[j+1]/maskImage.height);
                threeContour.push(point);
            }
            threeContours.push(threeContour);

            //find an enclosing contour for a decent clickable region (drawing, etc)
            const threeHull:THREE.Vector2[] = [];

            const expContour = expandedContours.get(i);

            if (expContour) {
                for (let j = 0; j < expContour.data32S.length; j+=2) {
                    const point = new THREE.Vector2(expContour.data32S[j]/expSize.width, expContour.data32S[j+1]/expSize.height);
                    threeHull.push(point);
                }
            } else {
                for (let j = 0; j < contour.data32S.length; j+=2) {
                    const point = new THREE.Vector2(contour.data32S[j]/maskImage.width, contour.data32S[j+1]/maskImage.height);
                    threeHull.push(point);
                }
            }

            threeHulls.push(threeHull);
            poly.delete();
        }

        contours.delete();
        expandedContours.delete();

        if (this.maskTexture) {
            this.maskTexture.refresh();
        }

        this.removeMeshes();

        const mx = new THREE.Matrix4().lookAt(this.plane.normal, new THREE.Vector3(), new THREE.Vector3(0,1,0));
        const _rotation = new THREE.Quaternion().setFromRotationMatrix(mx);

        const euler = new THREE.Euler().setFromQuaternion(_rotation);
        const rotation = new THREE.Quaternion().setFromEuler(new THREE.Euler(euler.x, euler.y, this.axisRotation));

        this.castContours(threeContours, threeHulls, rotation, calculateTransform);

        this._container.rotation.setFromQuaternion(rotation);
    }

    public lightingMean = 0;
    public lightingStdDev = 0;

    public backgroundMean = 0;
    public backgroundStdDev = 0;

    private projectPointsOntoPlane(contours: THREE.Vector2[][], contours3D:THREE.Vector3[][]) {
        const totalPoint = new THREE.Vector3();
        let numPoints = 0;

        for (let i = 0; i < contours.length; i++) {
            const contour = contours[i];
            const points3D: THREE.Vector3[] = [];
            for (let j = 0; j < contour.length; j++) {

                const point3D = this.getPlaneIntersection(contour[j]);

                if (point3D && point3D.length() <= MAX_PROJECTED_DISTANCE) {
                    points3D.push(point3D);
                    totalPoint.x += point3D.x;
                    totalPoint.y += point3D.y;
                    totalPoint.z += point3D.z;
                    numPoints += 1
                }
            }

            if (points3D.length > 2) {
                points3D.push(points3D[0]);//join start and end
                contours3D.push(points3D);
            }
        }

        return new THREE.Vector3(
            totalPoint.x / numPoints,
            totalPoint.y / numPoints,
            totalPoint.z / numPoints);
    }

    private castContours(contours: THREE.Vector2[][], hulls: THREE.Vector2[][], rotation:THREE.Quaternion, calculateTransform:boolean) {

        const contours3D:THREE.Vector3[][] = [];
        const center3D = this.projectPointsOntoPlane(contours, contours3D);

        const hull3D:THREE.Vector3[][] = [];
        this.projectPointsOntoPlane(hulls, hull3D);

        if (calculateTransform) {
            this._pointTransform = new THREE.Matrix4().compose(center3D, rotation, new THREE.Vector3(1,1,1)).invert()
        }

        const min = new THREE.Vector2(Number.MAX_SAFE_INTEGER,Number.MAX_SAFE_INTEGER);
        const max = new THREE.Vector2(Number.MIN_SAFE_INTEGER,Number.MIN_SAFE_INTEGER);
        const contoursPlane = [];

        let index = 0;
        for (const contour of contours3D) {
            const translatedContour = [];
            for (const point3D of contour) {
                const point2D = this.getSurfacePosition(point3D);
                translatedContour.push(point2D);
                min.x = Math.min(min.x, point2D.x);
                min.y = Math.min(min.y, point2D.y);
                max.x = Math.max(max.x, point2D.x);
                max.y = Math.max(max.y, point2D.y);
            }
            translatedContour.push(translatedContour[0]); //close loop

            const hull = index < hull3D.length ? hull3D[index] : contour;
            const translatedHull = [];
            for (const point3D of hull) {
                translatedHull.push(this.getSurfacePosition(point3D));
            }
            translatedHull.push(translatedHull[0]); //close loop

            const shape = new THREE.Shape();
            shape.setFromPoints(translatedHull);
            contoursPlane.push(shape);

            //add outside lines
            const lineMaterial = new LineMaterial({
                vertexColors: false,
                transparent:true,
                opacity: 0.0,
                color:new THREE.Color("white").getHex(),
                linewidth: 2.0,
                blending:THREE.AdditiveBlending,
                resolution: new THREE.Vector2(this.context.gl.renderer.domElement.width * window.devicePixelRatio, this.context.gl.renderer.domElement.height * window.devicePixelRatio),
                dashed: false,
                depthTest:false
            });

            const positions = [];
            const colors = [];
            for (const point of translatedContour) {
                positions.push(point.x, point.y, 0);
                colors.push( this.color.r, this.color.g, this.color.b )
            }

            const lineGeometry = new LineGeometry();
            lineGeometry.setPositions(positions);
            lineGeometry.setColors(colors);

            const line = new Line2(lineGeometry, lineMaterial );
            line.computeLineDistances();
            line.scale.set( 1, 1, 1 );
            line.visible = false;
            line.renderOrder = 10000;

            this._outlines.push(line);
            this._container.add(line);

            index += 1;
        }

        //add each shape
        for (const shape of contoursPlane) {
            //add shape
            const geometry = new THREE.ShapeBufferGeometry(shape);
            const mesh = new THREE.Mesh(geometry, this.material);
            mesh.renderOrder = 10000;
            this._container.add(mesh);
            this._shapeMeshes.push(mesh);

            //const shadowMaterial = new THREE.ShadowMaterial({opacity:0.01});
            if (this._placeholderMaterial) {
                const placeholderShape = new THREE.Shape();
                const halfWidth = 50; //infinite-ish
                placeholderShape.moveTo( -halfWidth, -halfWidth);
                placeholderShape.lineTo( halfWidth, -halfWidth);
                placeholderShape.lineTo( halfWidth, halfWidth);
                placeholderShape.lineTo( -halfWidth, halfWidth);
                placeholderShape.lineTo( -halfWidth, -halfWidth);

                const placeholderGeometry = new THREE.ShapeBufferGeometry(placeholderShape);
                const placeholderMesh = new THREE.Mesh(placeholderGeometry, this._placeholderMaterial.threeMaterial);

                if (this.context.scene) {
                    //placeholderMesh.rotateZ(this.context.scene.groundRotation);
                    //console.log("rotation", this.context.scene.groundRotation)
                }

                placeholderMesh.receiveShadow = this.length() > 0;
                placeholderMesh.castShadow = false;
                this._container.add(placeholderMesh);
                this._placeholderMeshes.push(placeholderMesh);
            }
        }

        //calc extents
        const xSpan = 2 * Math.max(max.x - min.x, 2);
        const ySpan = 2 * Math.max(max.y - min.y, 2);

        this._extent = new THREE.Vector2(xSpan, ySpan);
        if (calculateTransform) {
            this._container.position.set(center3D.x, center3D.y, center3D.z);
        }
    }

    private regenerateMeshes(calculateTransform:boolean) {
        if (this.maskTexture && this.maskTexture.canvas) {

            if (this.context.scene?.placeholderTexture) {
                this._placeholderMaterial = new CBARStandardMaterial(this.context);
                this._placeholderMaterial.threeMaterial.map = this.context.scene.placeholderTexture.threeTexture;
                this._placeholderMaterial.repeat = new THREE.Vector2(1,1);
                this._placeholderMaterial.threeMaterial.alphaMap = this.maskTexture.threeTexture;

                if (this.context.scene.lightingTexture?.threeTexture) {
                    this._placeholderMaterial.threeMaterial.lightMap = this.context.scene.lightingTexture.threeTexture;
                }
            }

            this.findContours(this.maskTexture.canvas, calculateTransform);
        }
        this.sortAssets();
    }

    removeFromScene() : void {
        if (this._commitTimer) {
            window.clearInterval(this._commitTimer);
        }
        this.removeMeshes();
        super.removeFromScene();
    }

    sceneLoaded() {
        this.axisRotation = this.context.scene!.groundRotation;
        this.regenerateMeshes(true);

        if (this.maskTexture) {
            this.maskTexture.blendResults = !this.getSuperpixelsImage();
        }
        this.needsUpdate();
    }

    public get receivesEvents() : boolean {
        return true
    }
}

