import {DataItem} from "./DataItem";
import {CBARAssetType, CBARSurfaceType} from "../CBARTypes";
import {uniq} from "../internal/Utils";

export enum DataFilterOperator {
    AND,
    OR
}

export abstract class DataFilter {

    protected constructor(public operator=DataFilterOperator.AND) {

    }

    public abstract matches(item: DataItem) : boolean

    public get enabled() : boolean {
        return true
    }

    public abstract get value() : string

    public apply(items: DataItem[]) : DataItem[] {
        return items.filter(item=>{this.matches(item)})
    }

    public static applyFilters(filters:DataFilter[], items:DataItem[]) {

        //apply OR first
        let sortedFilters = filters.filter(filter=>filter.enabled)
        sortedFilters = sortedFilters.filter(filter=>filter.enabled).sort((a,b)=>{
            if (a.operator === b.operator) return 0
            return a.operator === DataFilterOperator.OR ? -1 : 1
        })

        if (sortedFilters.length === 0) {
            return items
        }

        //OR operator must be first for this to work
        const filtered = items.filter(item => {
            let isMatch = false
            let index = 0
            sortedFilters.forEach(filter => {
                if (filter.operator === DataFilterOperator.OR) {
                    isMatch = isMatch || filter.matches(item)
                } else {
                    isMatch = (isMatch || index == 0) && filter.matches(item)
                }
                index ++
            })
            return isMatch
        })

        //console.log(`Applying ${sortedFilters.length} filters to ${items.length} items resulted in ${filtered.length} items`)

        return filtered
    }
}

export class AssetTypeFilter extends DataFilter {

    public constructor(public assetType:CBARAssetType, operator=DataFilterOperator.AND) {
        super(operator)
    }

    matches(item: DataItem): boolean {
        return item.assetTypes.indexOf(this.assetType) >= 0;
    }

    public get value() : string {
        return this.assetType
    }
}

export class SurfaceTypeFilter extends DataFilter {

    public constructor(public surfaceType:CBARSurfaceType, operator=DataFilterOperator.AND) {
        super(operator)
    }

    matches(item: DataItem): boolean {
        return item.surfaceTypes.indexOf(this.surfaceType) >= 0
    }

    public get value() : string {
        return this.surfaceType
    }
}

export class CustomFilter extends DataFilter {

    public constructor(private isMatch:(item: DataItem)=>boolean, private getValue:()=>string, operator=DataFilterOperator.AND) {
        super(operator)
    }

    matches(item: DataItem): boolean {
        return this.isMatch(item)
    }

    public get value() : string {
        return this.getValue()
    }
}