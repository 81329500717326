import {CBARTile} from "./CBARTile";

import * as THREE from "three";
import {CBARTiledAsset} from "./CBARTiledAsset";
import {CBARContext} from "../CBARContext";
import {CBARStandardMaterial} from "../components/CBARStandardMaterial";

export class CBARCircularTile extends CBARTile {

    private _container = new THREE.Object3D();

    constructor(parent: CBARTiledAsset, context:CBARContext, material: CBARStandardMaterial) {
        super(parent, context, material);

        //this.geometry = new THREE.CircleBufferGeometry(0.5, 360, this.tileThickness);
        this.geometry = new THREE.CylinderBufferGeometry(0.5, 0.5, this.tileThickness, 64, 1);

        this.mesh = new THREE.Mesh(this.geometry, this.material.threeMaterial);
        this.mesh.castShadow = parent.castShadow;
        this.mesh.receiveShadow = false;
        this.mesh.rotateOnAxis(new THREE.Vector3(1,0,0), Math.PI/2);


        const uvs = this.geometry.attributes.uv.array;
        this.geometry.setAttribute( 'uv2', new THREE.BufferAttribute( uvs, 2 ) );

        this._container.add(this.mesh);

        this.setRenderObject(this._container);
    }
}