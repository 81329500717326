import {DataItem, MonitoredArray} from "./DataItem";
import {SwatchItem} from "./SwatchItem";
import {ProductBrand} from "./ProductBrand";
import {Product} from "./Product";
import {CollectionConfig, DataFilterConfig} from "cambrian-base";

export class ProductCollection extends DataItem implements SwatchItem {

    constructor(dataUrl?:string) {
        super(dataUrl);
        this.description = "collection";
    }

    private _brand?: ProductBrand;
    public collection?: ProductCollection;

    private _collections: MonitoredArray<ProductCollection> = new MonitoredArray(()=>this.needsUpdate());
    public get collections() {
        return this._collections.sort((a, b) => a.orderIndex - b.orderIndex)
    }
    public set collections(value) {
        this._collections = new MonitoredArray(()=>this.needsUpdate(), ...value)
    }

    private _products: MonitoredArray<Product> = new MonitoredArray(()=>this.needsUpdate());
    public get products() {
        return this._products.sort((a, b) => a.orderIndex - b.orderIndex)
    }
    public set products(value) {
        this._products = new MonitoredArray(()=>this.needsUpdate(), ...value)
    }

    public get brand(): ProductBrand {
        return this.collection ? this.collection.brand : this._brand!
    }

    public set brand(value) {
        if (!this.collection) {
            this._brand = value
        }
    }

    public get parent():DataItem|undefined {
        return this.collection ? this.collection : this._brand
    }

    public get children(): DataItem[] {
        return this._collections.length ? this.collections : this.products
    }

    public childName(plural:boolean): string {
        return plural ? "Products" : "Product"
    }

    public get hasColumns(): boolean {
        return this.products.length > 0 && !this.products.find(prod=>prod.hasColumns)
    }

    public get json(): CollectionConfig {
        return this._json;
    }

    public set json(value: CollectionConfig) {
        this._json = value;
    }

    private _uiFilters:DataFilterConfig[] = [];

    public get uiFilters(): DataFilterConfig[] {
        return this._uiFilters;
    }

    public load(json:CollectionConfig) {
        super.load(json);

        if (json.uiFilters) {
            this._uiFilters = json.uiFilters;
        }

        this._collections.length = 0;
        if (json.collections) {
            for (const collectionJson of json.collections) {
                const collection = new ProductCollection();
                collection.collection = this;
                collection.load(collectionJson);
                if (collection.enabled) {
                    this._collections.push(collection)
                }
            }
        }

        this._products.length = 0;
        if (json.products) {
            for (const productJson of json.products) {
                const product = new Product();
                product.collection = this;
                product.load(productJson);
                if (product.enabled) {
                    this._products.push(product)
                }
            }
        }
    }
}