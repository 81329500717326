import {CBARObject} from "./CBARObject";
import * as THREE from "three";
import {CBARError, CBARErrorCode} from "../CBARError";
import {CBARTextureType} from "./CBARMaterial";
import {CBARImage} from "./CBARImage";
import {CBARContext} from "../CBARContext";
import {ImageCrop} from "cambrian-base";
import {LuminanceFormat} from "three/src/constants";

export class CBARTexture extends CBARObject<CBARTexture> {

    public threeTexture:THREE.Texture|null = null;
    public canvas?:HTMLCanvasElement;

    public constructor(context:CBARContext, public type:CBARTextureType, name=type) {
        super(context);
        this.image.name = name
    }

    public image = new CBARImage(this.context);
    public path?:string;

    public get imageDimensions() : THREE.Vector2 | undefined {
        if (this.canvas) {
            return new THREE.Vector2(this.image.width, this.image.height)
        }
        return undefined
    }

    load(basePath:string|undefined, path:string, crop?:ImageCrop) {

        this.path = path;

        return new Promise<CBARTexture>((resolve,reject) => {

            this.image.load(basePath, path, crop).then(()=>{
                const success = this.loadImage(this.image, false);

                if (success) {
                    resolve(this)
                } else {
                    this.rejectPromise(reject, new CBARError(`Texture at path ${path} was invalid`, CBARErrorCode.ImageNotFoundAtPath, path));
                }
            }).catch(error=>{
                this.rejectPromise(reject, error)
            })
        })
    }

    public loadImage(image:CBARImage, greyscale:boolean) : boolean {
        this.image = image;
        if (!this.canvas) {
            this.canvas = document.createElement("canvas");
        }

        const maxTextureDimension = Math.min(4096, this.context.gl.renderer.capabilities.maxTextureSize / 4);

        this.canvas.width = Math.min(THREE.MathUtils.ceilPowerOfTwo(image.width), maxTextureDimension);
        this.canvas.height = Math.min(THREE.MathUtils.ceilPowerOfTwo(image.height), maxTextureDimension);

        const ctx = this.canvas.getContext("2d");

        if (ctx) {
            ctx.drawImage(image.image, 0, 0, this.canvas.width, this.canvas.height);

            this.threeTexture = new THREE.Texture(this.canvas);
            this.threeTexture.format =  greyscale ? THREE.LuminanceFormat : (image.isJPEG ? THREE.RGBFormat : THREE.RGBAFormat);
            this.threeTexture.anisotropy = this.context.gl.renderer.capabilities.getMaxAnisotropy();
            this.threeTexture.needsUpdate = true;

            return true
        }
        return false
    }

    refresh() {
        if (this.threeTexture) {
            this.threeTexture.needsUpdate = true;
        }
    }

    public unload() {
        super.unload();

        if (this.canvas) {
            this.canvas.width = this.canvas.height = 1; //if for some reason it isn't garbage collected, at least it's small
            if (this.threeTexture) {
                this.threeTexture.needsUpdate = true;
            }
            delete this.canvas;
            this.canvas = undefined;
        }
        if (this.threeTexture) {
            this.threeTexture.dispose();
            // @ts-ignore
            delete this.threeTexture;
            this.threeTexture = null;
        }
    }

    public data() : any {
        return this.path
    }

    get description() : string {
        return "Texture"
    }
}
