import {Point} from "mirada";
import {OpticalFlowCluster, OpticalFlowFeature} from "./CBAROpticalFlowNode";
import {INT_MAX} from "../Utils";
import {euclideanDistSq} from "../Math";
import {Rect} from "gammacv";

export class CBARTrackedPoint {
    constructor(private _origin:Point) {
        this._trackingStart = _origin;
    }

    public get point() {
        if (this.feature && this._trackingStart) {
            const dx = this.feature.currentPoint.point.x - this._trackingStart.x;
            const dy = this.feature.currentPoint.point.y - this._trackingStart.y;
            return new cv.Point(this._origin.x + dx, this._origin.y + dy)
        }
        return this._origin;
    }

    public set point(value) {
        this._origin = value;
        this._trackingStart = this.feature ? new cv.Point(this.feature.currentPoint.point.x, this.feature.currentPoint.point.y) : this._origin;
    }

    private _feature?:OpticalFlowFeature
    public get feature() {
        return this._feature;
    }

    private _trackingStart?:Point

    public set feature(value) {
        this._feature = value;
        if (value) {
            this._trackingStart = new cv.Point(value.currentPoint.point.x, value.currentPoint.point.y);
        }
    }
}

let index = 0;
export abstract class CBARTrackedObject {
    protected constructor(public points:CBARTrackedPoint[], public frameIndex:number, public frameTime:number, public maxAge:number) {
        this.trackingFrameIndex = frameIndex;
    }

    public readonly id = index++;

    private _midpoint?:Point;

    public get midpoint() : Point {
        if (this.points.length == 0) return new cv.Point(0,0);

        if (!this._midpoint) {
            let totalX = 0;
            let totalY = 0;
            this.points.forEach(p=>{
                totalX += p.point.x;
                totalY += p.point.y;
            })
            this._midpoint = new cv.Point(totalX/this.points.length,totalY/this.points.length);
        }
        return this._midpoint;
    }

    public abstract isMatch(obj:CBARTrackedObject):boolean;

    public merge(obj:CBARTrackedObject, alpha=0.5) {
        const beta = 1.0 - alpha;
        for (let i=0; i<this.points.length; i++) {
            const x = (alpha * this.points[i].point.x + beta * obj.points[i].point.x);
            const y = (alpha * this.points[i].point.y + beta * obj.points[i].point.y);
            this.points[i].point = new cv.Point(x, y);
        }
    }

    public get roi() {
        return new Rect();
    }

    public cluster?:OpticalFlowCluster

    public invalidate(feature:OpticalFlowFeature) {
        this.points.filter(p=>p.feature === feature).forEach(p=>p.feature = undefined);
    }

    public get unassigned() {
        return this.points.filter(p=>p.feature === undefined);
    }

    public get assigned() {
        return this.points.filter(p=>p.feature !== undefined);
    }

    public assign(oPoints:OpticalFlowFeature[]) {
        this.unassigned.forEach(p=>{
            let bestFeature:OpticalFlowFeature|undefined;
            let bestDistance = INT_MAX;
            oPoints.forEach(o=>{
                const distance =  euclideanDistSq(p.point, o.currentPoint.point);
                if (distance < bestDistance) {
                    bestDistance = distance;
                    bestFeature = o;
                }
            })
            if (bestFeature) {
                p.feature = bestFeature;
            }
        })
    }

    public confirmations?:number
    public trackingFrameIndex:number
}
