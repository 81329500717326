import {BackendConfig} from "cambrian-base";
import {CBARDebug} from "../core";
import {DebugLevel, LogLevel} from "../core/internal/GlobalLogger";

export class ProcessImageResults {
    constructor(readonly semanticUrl: string, readonly lightingUrl: string,
                readonly dataUrl: string | undefined, readonly superpixelsUrl: string | undefined,
                readonly fieldOfView: number, readonly cameraRotation: [number, number, number],
                readonly cameraElevation: number, readonly floorRotation: number) {

    }
}

//status codes: https://gist.github.com/scokmen/f813c904ef79022e84ab2409574d1b45
//const INTERNAL_SERVER_ERROR = 500
//const GATEWAY_TIMEOUT = 504
//const RETRY_STATUSES = [INTERNAL_SERVER_ERROR, GATEWAY_TIMEOUT]

export interface ImageProcessor {
    processImage(imageId: string, params: { [key: string]: string}): Promise<ProcessImageResults | null>
}

export type GetUploadUrlsRequest = {
    key?: string // When defined, will request sub-urls for existing room
    names: string[] // Sub-names like mask, preview
}

export type GetUploadUrlsResult = {
    key: string // Room id
    uploadUrl?: string // Upload url for roomId's background
    subKey: string // Sub-room id for the room id
    subUploadUrls: { [name: string]: string } // Sub-upload urls for requested names for subRoomId
}

export interface ImageUploader {
    getUploadUrls(request: GetUploadUrlsRequest): Promise<GetUploadUrlsResult | null>
    upload(url: string, content: File | Blob): Promise<boolean>
}

export class CambrianImageProcessor implements ImageProcessor {
    constructor(readonly segmentUrl: string) {

    }

    async processImage(imageId: string, params: { [key: string]: string}): Promise<ProcessImageResults | null> {
        let segmentUrl = `${this.segmentUrl}/${imageId}?`;

        Object.keys(params).forEach((key, index)=>{
            const kvString = `${key}=${params[key]}`;
            segmentUrl += (index ? `&${kvString}` : kvString);
        });

        console.log(`Segmenting at ${segmentUrl}`);

        return this.getProject(segmentUrl, imageId)
    }

    async getProject(segmentUrl:string, imageId:string) {

        try {
            await fetch(segmentUrl);
        } catch (error) {
            console.log(error)
        }

        return this.awaitProject(segmentUrl, imageId)
    }

    async awaitProject(segmentUrl:string, imageId:string, start=Date.now()): Promise<ProcessImageResults | null> {
        //it will appear soon at s3
        const config = getConfig();

        const timeout = config.timeout ? config.timeout : 60000
        const testFrequency = 3000
        const resultsBucket = config.hostingUrl;
        const projectBucket = `${resultsBucket}/${imageId}`;
        const dataFile = `${projectBucket}/data_v3.json`;

        let success = false;

        try {
            const response = await fetch(dataFile, { method: 'HEAD' });
            success = response.ok
        } catch (error) {
            console.log(error)
        }

        if (success) {
            console.log("Success!")
            await usleep(2000); //wait one more second
            return new ProcessImageResults(
                `${projectBucket}/mask.png`,
                `${projectBucket}/lighting.png`,
                `${projectBucket}/data_v3.json`,
                `${projectBucket}/superpixels.png`,
                53,
                [0, 0, 0],
                1,
                0);
        }

        await usleep(testFrequency);

        const elapsed = Date.now() - start;
        console.log(`Waited for ${elapsed}ms`);

        if (elapsed < timeout) { //5xx status code e.g 504 (timeout) 500 (server error)
            return this.awaitProject(segmentUrl, imageId, start)
        }

        return null
    }
}

export class PresignedUrlImageUploader implements ImageUploader {
    constructor(readonly uploadUrl: string) {
        //console.log(`Using PresignedUrlImageUploader at ${uploadUrl}`)
    }

    async getUploadUrls(req: GetUploadUrlsRequest): Promise<GetUploadUrlsResult | null> {

        let url = `${this.uploadUrl}/?imagenames=${req.names.join(",")}`;
        if (req.key) {
            url += `&key=${req.key}`
        }

        const presignedResponse = await fetch(url);
        if (!presignedResponse.ok) {
            console.warn("Response was invalid");
            return null
        }

        const presignedResponseData = await presignedResponse.json();

        if (presignedResponseData.key) {
            return {
                key: presignedResponseData.key,
                uploadUrl: presignedResponseData.uploadUrl,
                subKey: presignedResponseData.subKey,
                subUploadUrls: presignedResponseData.subUploadUrls
            }
        }

        return null
    }

    async upload(url: string, content: File | Blob): Promise<boolean> {
        // Upload the content to the presigned url
        const uploadResponse = await fetch(url, {
            method: "PUT",
            body: content
        });

        return uploadResponse.ok
    }
}

export class DirectImageUploader implements ImageUploader {
    constructor(readonly uploadUrl:string) {
        console.log(`Using DirectImageUploader at ${uploadUrl}`)
    }

    async getUploadUrls(req: GetUploadUrlsRequest): Promise<GetUploadUrlsResult | null> {
        throw new Error("getUploadUrls not implemented for DirectImageUploader")
    }

    async upload(url: string, content: File | Blob): Promise<boolean> {
        throw new Error("upload not implemented for DirectImageUploader")
    }
}

export type CBServerConfigExternal = BackendConfig & {
    logLevel?:DebugLevel
    maxWallCount?:number,

    //appearance
    placeholderPath?:string,
    hoverBGOpacity?:number,
    clickBGOpacity?:number,
    selectedBGOpacity?:number,

    hoverOutlineOpacity?:number,
    clickOutlineOpacity?:number,
    selectedOutlineOpacity?:number,

    //specific
    classifierPath?:string
    classifierMaxRegions?:number,
    classifierUpdateFrequency?:number,
    classifierMinSize?:[number, number],
    classifierMaxSize?:[number, number],

    debug?:CBARDebug
}

type CBServerConfig = {
    maxWallCount:number,

    //appearance
    hoverBGOpacity:number,
    clickBGOpacity:number,
    selectedBGOpacity:number,

    hoverOutlineOpacity:number,
    clickOutlineOpacity:number,
    selectedOutlineOpacity:number,

    classifierMaxRegions:number,
    classifierUpdateFrequency:number,
    debug:CBARDebug

} & CBServerConfigExternal

let _config:CBServerConfig|undefined;

// Make sure either direct upload url or presigned upload url are defined, and not both (exclusive or).
export function cbInitialize(config:CBServerConfigExternal) {

    if (config.logLevel) {
        LogLevel(config.logLevel);
    }

    _config = { ...config,
        hoverBGOpacity: config.hoverBGOpacity ? config.hoverBGOpacity : 0.0,
        clickBGOpacity: config.clickBGOpacity ? config.clickBGOpacity : 0.0,
        selectedBGOpacity: config.selectedBGOpacity ? config.selectedBGOpacity : 0.0,

        hoverOutlineOpacity: config.hoverOutlineOpacity ? config.hoverOutlineOpacity : 0.0,
        clickOutlineOpacity: config.clickOutlineOpacity ? config.clickOutlineOpacity : 1.0,
        selectedOutlineOpacity: config.selectedOutlineOpacity ? config.selectedOutlineOpacity : 0.5,
        maxWallCount:config.maxWallCount !== undefined ? config.maxWallCount : 100,

        classifierMaxRegions:config.classifierMaxRegions !== undefined ? config.classifierMaxRegions : 1,
        classifierUpdateFrequency:config.classifierUpdateFrequency !== undefined ? config.classifierUpdateFrequency : 1000,

        debug:config.debug ? config.debug : CBARDebug.None,
    };
}

export function getConfig():CBServerConfig {
    if (!_config) {
        throw Error("react-home-ar is not initialized, call cbInitialize(config:CBServerConfig)")
    }
    return _config
}

export const usleep = (ms=1000) => new Promise<void>(resolve => setTimeout(() => resolve(), ms))