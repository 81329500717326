import {BrandConfig} from "cambrian-base";
import {DataItem, MonitoredArray} from "./DataItem";
import {SwatchItem} from "./SwatchItem";
import {ProductCollection} from "./ProductCollection";
import {SceneCollection} from "./SceneCollection";

export class ProductBrand extends DataItem implements SwatchItem {

    constructor(dataUrl?:string) {
        super(dataUrl);
        this.description = "brand";
    }

    public parentBrand?: ProductBrand;

    private _subBrands: MonitoredArray<ProductBrand> = new MonitoredArray(()=>this.needsUpdate());
    public get subBrands() {
        return this._subBrands.sort((a, b) => a.orderIndex - b.orderIndex)
    }

    private _collections: MonitoredArray<ProductCollection> = new MonitoredArray(()=>this.needsUpdate());
    public get collections() {
        return this._collections.sort((a, b) => a.orderIndex - b.orderIndex)
    }
    public set collections(value) {
        this._collections = new MonitoredArray(()=>this.needsUpdate(), ...value)
    }

    private _sceneCollections: MonitoredArray<SceneCollection> = new MonitoredArray(()=>this.needsUpdate());
    public get sceneCollections() {
        return this._sceneCollections.sort((a, b) => a.orderIndex - b.orderIndex)
    }
    public set sceneCollections(value) {
        this._sceneCollections = new MonitoredArray(()=>this.needsUpdate(), ...value)
    }

    public get root(): ProductBrand {
        return this.parentBrand ? this.parentBrand : this
    }

    public get brand(): ProductBrand {
        return this
    }

    public get parent():DataItem|undefined {
        return this.parentBrand
    }

    public get children(): DataItem[] {
        return this._subBrands.length ? this.subBrands : this.collections
    }

    public childName(plural:boolean): string {
        return plural ? "Collections" : "Collection"
    }

    public load(json:BrandConfig) {
        super.load(json);

        this._subBrands.length = 0;
        if (json.brands) {
            for (const brandJson of json.brands) {
                const brand = new ProductBrand();
                brand.parentBrand = this;
                brand.load(brandJson);
                if (brand.enabled) {
                    this._subBrands.push(brand)
                }
            }
        }

        this._collections.length = 0;
        if (json.collections) {
            for (const collectionJson of json.collections) {
                const collection = new ProductCollection();
                collection.brand = this;
                collection.load(collectionJson);
                if (collection.enabled) {
                    this._collections.push(collection)
                }
            }
        }

        this._sceneCollections.length = 0;
        if (json.sceneCollections) {
            for (const collectionJson of json.sceneCollections) {
                const collection = new SceneCollection();
                collection.brand = this;
                collection.load(collectionJson);
                if (collection.enabled) {
                    this._sceneCollections.push(collection)
                }
            }
        }
    }
}