import {Point} from "mirada";
import {Rect} from "gammacv";

export const euclideanDist = (p:Point, q:Point) => {
    return Math.sqrt(euclideanDistSq(p,q));
}

export const euclideanDistSq = (p:Point, q:Point) => {
    let diff = new cv.Point(p.x-q.x, p.y-q.y);
    return diff.x*diff.x + diff.y*diff.y;
}



export const regionsOverlap = (l1:Point, r1:Point, l2:Point, r2:Point) => {

    // To check if either rectangle is actually a line
    // For example :  l1 ={-1,0}  r1={1,1}  l2={0,-1}
    // r2={0,1}

    if (l1.x == r1.x || l1.y == r1.y || l2.x == r2.x
        || l2.y == r2.y) {
        // the line cannot have positive overlap
        return false;
    }

    // If one rectangle is on left side of other
    if (l1.x >= r2.x || l2.x >= r1.x)
        return false;

    // If one rectangle is above other
    return !(l1.y >= r2.y || l2.y >= r1.y);
}

const rectInside = (rect:Rect, ofRect:Rect) => {
    const points = rect.toArray();
    for (let i=0; i<points.length; i+=2) {
        if (ofRect.isInRect(points[i], points[i+1])) {
            return true;
        }
    }
    return false;
}

export const rectsOverlap = (rectA:Rect, rectB:Rect) => {
    return rectInside(rectA, rectB) || rectInside(rectB, rectA);
}