export enum CBARErrorCode {
    General,
    AssetLoad,
    InvalidType,
    NullReference,
    ImageNotFoundAtPath,
}

export class CBARError extends Error {
    constructor(message:string, public readonly code?:CBARErrorCode, public readonly object?:any) {
        super(message)
    }
}