import * as THREE from "three";
import {CBARLightAsset, CBARLightAssetProperties} from "./CBARLightAsset";
import {CBARContext} from "../../CBARContext";

interface CBARPointLightProperties extends CBARLightAssetProperties {
    distance?:number
    power?:number
    decay?:number
}

export class CBARPointLight extends CBARLightAsset {

    constructor(context:CBARContext) {
        super(context);

        this._light.castShadow = true;
        this._light.distance = Infinity;
        this._light.lookAt(0, 0, 0);
        this.setRenderObject(this.light)
    }

    protected _light = new THREE.PointLight();

    public get light():THREE.Light {
        return this._light
    }

    load(basePath:string|undefined, json:CBARPointLightProperties) : Promise<CBARPointLight> {
        return new Promise<CBARPointLight>((resolve) => {
            return super.load(basePath, json).then(()=>{
                if (json.distance !== undefined) {
                    this._light.distance = json.distance
                }

                if (json.power !== undefined) {
                    this._light.power = json.power
                }

                if (json.decay !== undefined) {
                    this._light.decay = json.decay
                }

                resolve(this)
            })
        })
    }

    public data() : any {
        const data:any = super.data();

        data.distance = this._light.distance;
        data.power = this._light.power;
        data.decay = this._light.decay;

        return data
    }

    get description() : string {
        return "Point light"
    }
}