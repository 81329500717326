import {CBARAsset} from "../CBARAsset";
import * as THREE from "three";
import {CBARContext} from "../../CBARContext";
import {CBARObject3DProperties} from "../../components";

export interface CBARLightAssetProperties extends CBARObject3DProperties {
    color?:string
    intensity?:number
}

export abstract class CBARLightAsset extends CBARAsset {

    public abstract get light():THREE.Light

    load(basePath:string|undefined, json: CBARLightAssetProperties): Promise<CBARLightAsset> {

        if (json.color) {
            this.light.color = new THREE.Color(parseInt(json.color, 16))
        }

        if (json.intensity) {
            this.light.intensity = json.intensity
        }

        return new Promise<CBARLightAsset>((resolve) => {
            return super.load(basePath, json).then(()=>{
                resolve(this)
            })
        })
    }

    public data() : any {
        const data:any = super.data();

        data.color = this.light.color.getHexString();
        data.intensity = this.light.intensity;

        return data
    }
}