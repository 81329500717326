import * as THREE from "three";
import {CBARObject} from "./CBARObject";
import {CBARCameraIntrinsics, CBARCameraProperties} from "./CBARCameraIntrinsics";
import {CBARSceneGeometry, CBARSceneGeometryProperties} from "./CBARSceneGeometry";
import {CBARImageCollection, CBARImageDictionary} from "./CBARImageCollection";
import {CBARContext, isDrawingTool} from "../CBARContext";
import {CBAREvent, CBAREventType, CBARHighlightState, CBARSurfaceType, CBARToolMode} from "../CBARTypes";
import {CBARAssetCollection} from "./CBARAssetCollection";
import {CBARTexture} from "./CBARTexture";
import {CBARTextureType} from "./CBARMaterial";

import {CBARAsset} from "../assets";
import {CBARSurface} from "./CBARSurface";
import {RGBELoader} from "three/examples/jsm/loaders/RGBELoader";
import {CBARImage} from "./CBARImage";
import {CBARError} from "../CBARError";
import {CBARSuperpixelsImage} from "./CBARSuperpixelsImage";
import {getConfig} from "../../backend";
import {InputArray} from "mirada/dist/src/types/opencv/_types";
import {PerformanceLogger} from "../internal/GlobalLogger";

window.cv = require('../includes/opencv.js')

let RENDER_DEPTH = false;

//TODO: remove this global variable. state callbacks should have worked but did not
let currentScene:CBARScene|undefined = undefined;

export interface CBARSceneProperties {
    formatVersion?:number
    name?:string,
    camera?:CBARCameraProperties,
    geometry?:CBARSceneGeometryProperties,
    assets?:any,
    floorRotation?:number
    groundRotation?:number
    lightingFactor?:number
    lightingOffset?:number
    images?:CBARImageDictionary,
    environment?:string
}

export interface CBScenePropertiesLegacy {
    cameraPosition: [number, number, number],
    cameraRotation: [number, number, number],
    floorRotation: number,
    fov: number,
    images: any
}

export class CBARScene extends CBARObject<CBARScene> {

    constructor(context:CBARContext) {
        super(context);
        this.cameraProperties = new CBARCameraIntrinsics(context);
        this.geometry = new CBARSceneGeometry(context);
        if (getConfig().placeholderPath) {
            this.placeholderTexture = new CBARTexture(context, CBARTextureType.albedo);
        }
        this.prepare()
    }

    public name = "surface";
    public cameraProperties:CBARCameraIntrinsics;
    public geometry:CBARSceneGeometry;

    public assets = new CBARAssetCollection(this.context);
    public images = new CBARImageCollection(this.context);
    public imageSize = new THREE.Vector2();

    public lightingTexture?:CBARTexture;
    public placeholderTexture?:CBARTexture;

    public groundRotation = 0.0;

    public lightingFactor = 1.0;
    public lightingOffset = 0.0;

    private convertLegacyScene(legacyScene:CBScenePropertiesLegacy) : CBARSceneProperties {
        const scene:CBARSceneProperties = {
            name:"generated",
            formatVersion: 0,
            floorRotation: legacyScene.floorRotation,
            camera: {
                fov: legacyScene.fov,
                position: [0,legacyScene.cameraPosition[1], 0],  //intentionally drop translation that's not elevation.
                rotation: legacyScene.cameraRotation
            },
        };

        scene.images = {
            "main": legacyScene.images['main'],
            "lighting": legacyScene.images['lighting']
        };

        if (legacyScene.images.hasOwnProperty("superpixels")) {
            scene.images['superpixels'] = legacyScene.images['superpixels'];
        }

        //-json.normal[0], -json.normal[2], json.normal[1]
        scene.geometry = {
            surfaces:[
                {
                    type: CBARSurfaceType.Floor,
                    name: "Floor",
                    normal: [0, 0, -1],
                    offset: 0,
                    images:{
                        "mask": legacyScene.images["masks"]["floor"]
                    }
                }
            ]
        };

        //console.log("Legacy scene converted.", scene);
        return scene;
    };

    load(basePath:string|undefined, _json:CBARSceneProperties|CBScenePropertiesLegacy, surfaceTypes?:CBARSurfaceType[], room?:string|null|undefined, subroom?:string|null|undefined) {

        const json = (_json as CBARSceneProperties).formatVersion ? _json as CBARSceneProperties : this.convertLegacyScene(_json as CBScenePropertiesLegacy);

        return new Promise<CBARScene>((resolve, reject) => {

            if (json.name) {
                this.name = json.name
            }

            const promises:any[] = [];

            const placeholderPath = getConfig().placeholderPath;
            if (this.placeholderTexture && placeholderPath) {
                promises.push(this.placeholderTexture.load(undefined, placeholderPath));
            }

            if (json.camera) {
                promises.push(this.cameraProperties.load(basePath, json.camera))
            }

            if (json.floorRotation) {
                this.groundRotation = -1.0 * json.floorRotation; //old parameter was reversed
            } else if (json.groundRotation) {
                this.groundRotation = json.groundRotation;
            }

            if (json.geometry) {
                promises.push(this.geometry.load(basePath, json.geometry, surfaceTypes, room, subroom))
            }

            if (json.assets) {
                promises.push(this.assets.load(basePath, json.assets))
            }

            if (json.images) {
                if (json.images.hasOwnProperty("roomId")) {
                    delete json.images["roomId"];
                }
                if (!json.images.hasOwnProperty("main") && Object.values(json.images).length) {
                    const firstPath = Object.values(json.images)[0];
                    json.images["main"] = `${firstPath.substr(0, firstPath.lastIndexOf("/"))}/background`;
                }
                promises.push(this.images.load(basePath, json.images))
            }

            this.lightingFactor = json.lightingFactor ? json.lightingFactor : 1.0;
            this.lightingOffset = json.lightingOffset ? json.lightingOffset : 0.0;

            if (json.environment) {
                const url = `${basePath}/${json.environment}`;

                promises.push(new Promise((resolve, reject)=>{
                    console.log(`Loading HDR environment: ${url}`);
                    new RGBELoader()
                        .setDataType(THREE.UnsignedByteType)
                        .load(url, (texture)=>{
                            console.log("Successfully loaded HDR environment: ", url);
                            const envMap = pmremGenerator.fromEquirectangular( texture ).texture;
                            this.context.gl.scene.environment = envMap;

                            texture.dispose();
                            pmremGenerator.dispose();

                            resolve(this)
                        }, undefined, ()=>{
                            reject(new Error("Could not load HDR environment"))
                        });

                    const pmremGenerator = new THREE.PMREMGenerator( this.context.gl.renderer );
                    pmremGenerator.compileEquirectangularShader();
                }))
            }

            Promise.all(promises).then(()=>{

                if (this.backgroundImage) {
                    this.imageSize = new THREE.Vector2(this.backgroundImage.image.width, this.backgroundImage.image.height)
                } else {
                    reject(new CBARError("No background image found."));
                }

                if (this.lightingImage) {
                    this.lightingTexture = new CBARTexture(this.context, CBARTextureType.lightMap);
                    this.lightingTexture.loadImage(this.lightingImage, true);
                } else {
                    reject(new CBARError("No lighting image found."));
                }

                resolve(this)
            }).catch(error=>{
                reject(error)
            })

        })
    }

    public get backgroundImage() : CBARImage | undefined {
        if (this.images.containsKey("main")) {
            return this.images.values['main'] as CBARImage;
        }
    }

    public get lightingImage() : CBARImage | undefined {
        if (this.images.containsKey("lighting")) {
            return this.images.values['lighting'] as CBARImage;
        }
    }

    private _superPixels:CBARSuperpixelsImage | undefined;
    private get superpixelsImage() : CBARSuperpixelsImage | undefined {
        if (!this._superPixels && this.images.containsKey("superpixels")) {
            this._superPixels = new CBARSuperpixelsImage(this.images.values['superpixels'])
        }
        return this._superPixels;
    }

    private _cvBackground?:InputArray;
    get cvBackground() : InputArray | undefined {
        return this._cvBackground;
    }

    private _cvLightingSmall?:InputArray;
    get cvLightingSmall() : InputArray | undefined {
        return this._cvLightingSmall;
    }

    private _cvBackgroundSmall?:InputArray;
    get cvBackgroundSmall() : InputArray | undefined {
        return this._cvBackgroundSmall;
    }

    private async analyzeImages() {
        if (!this.backgroundImage || !this.lightingImage) return

        const bg = this.backgroundImage.image;
        const lighting = this.lightingImage.image;

        PerformanceLogger("analyzeImages", ()=>{
            this._cvBackground = cv.imread(bg);
            //keep them small.
            const scale = Math.max(200 / this._cvBackground.cols, 200 / this._cvBackground.rows);
            const size = new cv.Size(scale * this._cvBackground.cols, scale * this._cvBackground.rows);
            cv.cvtColor(this._cvBackground, this._cvBackground, cv.COLOR_RGBA2RGB, 0);
            this._cvBackgroundSmall = new cv.Mat();
            cv.resize(this._cvBackground, this._cvBackgroundSmall, size);

            this._cvLightingSmall = cv.imread(lighting);
            cv.cvtColor(this._cvLightingSmall, this._cvLightingSmall, cv.COLOR_RGBA2GRAY, 0);
            cv.resize(this._cvLightingSmall, this._cvLightingSmall, size);
        })
    }

    public get isEditable() : boolean {
        return !!this.superpixelsImage;
    }

    public data() : any {
        return {
            id:this.id,
            camera: this.cameraProperties.data(),
            geometry: this.geometry.data(),
            assets:this.assets.data(),
            images:this.images.data()
        }
    }

    public unload() {
        this.geometry.clearAll();
        this.assets.clearAll();
        this.images.clearAll();

        if (this._cvBackground) {
            this._cvBackground.delete();
            this._cvBackground = undefined;
        }

        if (this._cvBackgroundSmall) {
            this._cvBackgroundSmall.delete();
            this._cvBackgroundSmall = undefined;
        }

        if (this._cvLightingSmall) {
            this._cvLightingSmall.delete();
            this._cvLightingSmall = undefined;
        }
    }

    public envMap?:THREE.Texture;

    private prepare() {
        if (currentScene) {
            currentScene.unload()
        }
        currentScene = this

        //https://hdrihaven.com/
        //const url = 'https://threejs.org/examples/textures/piz_compressed.exr'
    }

    public render() {
        this.context.gl.renderer.clear();

        this.assets.render();
        this.geometry.render();

        if (RENDER_DEPTH) {
            this.context.gl.renderer.setRenderTarget(this.context.gl.renderTarget);
            this.context.gl.renderer.render(this.context.gl.scene, this.context.gl.camera);
            this.context.gl.renderer.setRenderTarget(null);
        }

        this.context.gl.renderer.render(this.context.gl.scene, this.context.gl.camera);
    }

    private _assetsByState: Map<string, CBARAsset|undefined> = new Map([ ]);

    public assetFor(state:CBARHighlightState) : CBARAsset|undefined {
        return this._assetsByState.get(state);
    }

    public setAssetFor(state:CBARHighlightState, asset:CBARAsset|undefined, event?:CBAREvent) {
        if (this.context.isDrawing && (state === CBARHighlightState.Selected || state === CBARHighlightState.Drag)) return; //ignore

        const existing = this.assetFor(state);
        if (existing) {
            existing.setState(state, false, event);
            this._assetsByState.set(state, existing);
        }
        if (asset) {
            asset.setState(state, true, event);
        }
        this._assetsByState.set(state, asset);
    }

    private _surfacesByState: Map<string, CBARSurface|undefined> = new Map([ ]);

    public surfaceFor(state:CBARHighlightState) : CBARSurface|undefined {
        return this._surfacesByState.get(state);
    }

    public setSurfaceFor(state:CBARHighlightState, surface: CBARSurface|undefined, event?:CBAREvent) {

        const existing = this.surfaceFor(state);
        if (existing) {
            existing.setState(state, false, event);
            this._surfacesByState.set(state, existing);
        }
        if (surface) {
            surface.setState(state, true, event);
        }
        this._surfacesByState.set(state, surface);
    }

    public handleEvent(event: CBAREvent) {

        this.assets.handleEvent(event);
        this.geometry.handleEvent(event);

        if (!isDrawingTool(this.context.toolMode)) {
            const handlers = event.context.getHandlers();

            window.setTimeout(()=>{
                handlers.forEach((handler)=>{
                    handler(event)
                })
            }, 0);
        }
    }

    toolModeChanged(mode:CBARToolMode) {
        this.assets.toolModeChanged(mode);
        this.geometry.toolModeChanged(mode);
    }

    sceneLoaded() {
        this.analyzeImages().then();
        PerformanceLogger("Scene loading", ()=>{
            this.assets.sceneLoaded();
            this.geometry.sceneLoaded();
        });
    }

    get description() : string {
        return "Scene"
    }

    getEventObjects(eventType:CBAREventType) : THREE.Object3D[] {
        return this.assets.getEventObjects(eventType).concat(this.geometry.getEventObjects(eventType))
    }
}
