import EXIF from "exif-js";
import {getConfig} from "../backend";
/**
 * Create an image file, with rotation applied to compensate for EXIF orientation, if needed.
 *
 * Optionally resize to a smaller maximum width.
 */

export function getRotatedFile(file: File, maxWidth: number | undefined): Promise<[File,number,number]> {

    return testImage().then(useorientation =>
        getOrientation(file, useorientation).then(orientation => applyRotation(file, orientation || 1, maxWidth || 999999)));
}

export function getFocalLength(file: any): Promise<number | undefined> {
    return focalLength(file);
}

export function getFocalLengthIn35mmFilm(file: any): Promise<number | undefined> {
    return focalLengthIn35mmFilm(file);
}

export function getTags(file: any): Promise<any | undefined> {
    return tags(file);
}

const orientationImage = "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/4QA6RXhpZgAATU0AKgAAAAgAAwESAAMAAAABAAYAAAEoAAMAAAABAAEAAAITAAMAAAABAAEAAAAAAAD/2wCEABsaGikdKUEmJkFCLy8vQkc/Pj4/R0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0cBHSkpNCY0PygoP0c/NT9HR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR//AABEIAAEAAgMBIgACEQEDEQH/xABLAAEBAAAAAAAAAAAAAAAAAAAABhABAAAAAAAAAAAAAAAAAAAAAAEBAAAAAAAAAAAAAAAAAAAAABEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEQMRAD8AmQAf/9k="

const testImage = () => new Promise<boolean>(resolve => {
    const image = new Image();

    image.onload = () => {
        resolve(Boolean(image.width === 2));
    };

    image.src = orientationImage;
});

export function blobToDataUri(blob:Blob) : Promise<string> {

    return new Promise<string>((resolve,reject)=> {
            const fileReader = new FileReader();

            fileReader.onload = () => {
                const dataUri = fileReader.result as string;
                if (dataUri) {
                    resolve(dataUri)
                } else {
                    reject(new Error("Invalid blob data"));
                }
            };
            fileReader.onerror = reject;
            fileReader.readAsDataURL(blob);
        }
    )
}

/**
 * @returns EXIF orientation value (or undefined)
 */
const getOrientation = (file: any, useorientation: boolean | undefined) => new Promise<number | undefined>(resolve => {
    if (!useorientation) { resolve(1); }
    EXIF.getData(file, function () {
        resolve(EXIF.getTag(file, "Orientation"));
    })

});

/**
 * @returns EXIF focal length value in mm (or undefined)
 */
const focalLength = (file: any) => new Promise<number | undefined>(resolve => {

    EXIF.getData(file, function () {
        resolve(EXIF.getTag(file, "FocalLength"));
    })

});


const focalLengthIn35mmFilm = (file: any) => new Promise<number | undefined>(resolve => {

    EXIF.getData(file, function () {
        resolve(EXIF.getTag(file, "FocalLengthIn35mmFilm"));
    });
});

const tags = (file: any) => new Promise< any| undefined>(resolve => {

    EXIF.getData(file, function () {
        const obj = EXIF.getAllTags(file);
        const map = new Map(Object.entries(obj));

        resolve(map);
    })
});

export const getAccelerationVector = (file: any) => {
    return new Promise<number[]|undefined>((resolve) => {
        if (!file || !file.length) {
            resolve(undefined);
            return;
        }

        const buffer = new ArrayBuffer(file.length);
        const view = new Int8Array(buffer);
        for (let i = 0; i < file.length; i++) {
            view[i] = file[i];
        }
        const dataView = new DataView(buffer);

        let offset = dataView.getUint32(12 * 8 + 12);

        const num1 = dataView.getInt32(offset);
        const den1 = dataView.getInt32(offset += 4);
        const num2 = dataView.getInt32(offset += 4);
        const den2 = dataView.getInt32(offset += 4);
        const num3 = dataView.getInt32(offset += 4);
        const den3 = dataView.getInt32(offset += 4);

        resolve([num1, den1, num2, den2, num3, den3]);
    });
};

/**
 * @returns File (with rotation applied to compensate for orientation, if any)
 */
const applyRotation = (file: File, orientation: number, maxWidth: number) => new Promise<[File,number,number]>(resolve => {

    const reader = new FileReader();

    reader.onload = () => {
        const url = reader.result as string;
        const image = new Image();

        image.onload = () => {

            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d")!;

            let { width, height } = image;

            const [outputWidth, outputHeight] = orientation >= 5 && orientation <= 8
                ? [height, width]
                : [width, height];

            const scale = outputWidth > maxWidth ? maxWidth / outputWidth : 1;

            width = Math.floor(width * scale);
            height = Math.floor(height * scale);

            // to rotate rectangular image, we need enough space so square canvas is used

            const wh = Math.max(width, height);

            // set proper canvas dimensions before transform & export

            canvas.width = wh;
            canvas.height = wh;

            // for some transformations output image will be aligned to the right of square canvas
            let rightAligned = false;

            //console.log("orientation of the photo is: " + orientation);
            // transform context before drawing image
            switch (orientation) {
                case 2: context.transform(-1, 0, 0, 1, wh, 0); rightAligned = true; break;
                case 3: context.transform(-1, 0, 0, -1, wh, wh); rightAligned = true; break;
                case 4: context.transform(1, 0, 0, -1, 0, wh); break;
                case 5: context.transform(0, 1, 1, 0, 0, 0); break;
                case 6: context.transform(0, 1, -1, 0, wh, 0); rightAligned = true; break;
                case 7: context.transform(0, -1, -1, 0, wh, wh); rightAligned = true; break;
                case 8: context.transform(0, -1, 1, 0, 0, wh); break;
                default: break;
            }

            // draw image

            context.drawImage(image, 0, 0, width, height);

            // copy rotated image to output dimensions and export it

            const canvas2 = document.createElement("canvas");

            canvas2.width = Math.floor(outputWidth * scale);
            canvas2.height = Math.floor(outputHeight * scale);

            const ctx2 = canvas2.getContext("2d");

            if (ctx2 === null) return;
            const sx = rightAligned ? canvas.width - canvas2.width : 0;
            ctx2.drawImage(canvas, sx, 0, canvas2.width, canvas2.height, 0, 0, canvas2.width, canvas2.height);
            canvas.width = canvas.height = 1;

            const dataURL = canvas2.toDataURL("image/jpeg");
            const BASE64_MARKER = ';base64,';

            const parts = dataURL.split(BASE64_MARKER);
            const contentType = parts[0].split(':')[1];
            const raw = window.atob(parts[1]);
            const rawLength = raw.length;

            const uInt8Array = new Uint8Array(rawLength);

            for (let i = 0; i < rawLength; ++i) {
                uInt8Array[i] = raw.charCodeAt(i);
            }

            const uploadFile: any = new Blob([uInt8Array], { type: contentType });
            resolve([uploadFile, canvas2.width, canvas2.height])
            canvas2.width = canvas2.height = 1;
        };

        image.src = url;
    };

    reader.readAsDataURL(file);
});