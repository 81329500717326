import * as THREE from "three";
import {CBARLightAsset, CBARLightAssetProperties} from "./CBARLightAsset";
import {CBARContext} from "../../CBARContext";
import {CBARVector} from "../../CBARTypes";

interface CBARSpotlightProperties extends CBARLightAssetProperties {
    target?:CBARVector
    distance?:number
    power?:number
    decay?:number
    angle?:number
    penumbra?:number
}

export class CBARSpotlight extends CBARLightAsset {

    constructor(context:CBARContext) {
        super(context);

        this._light.castShadow = true;
        this._light.distance = Infinity;
        this._light.lookAt(0, 0, 0);
        this.setRenderObject(this.light)
    }

    protected _light = new THREE.SpotLight();

    public get light():THREE.SpotLight {
        return this._light
    }

    load(basePath:string|undefined, json:CBARSpotlightProperties) : Promise<CBARSpotlight> {
        return new Promise<CBARSpotlight>((resolve) => {
            return super.load(basePath, json).then(()=>{
                if (json.target) {
                    this._light.target.position.set(json.target[0], json.target[1], json.target[2])
                }

                if (json.distance) {
                    this._light.distance = json.distance
                }

                if (json.power) {
                    this._light.power = json.power
                }

                if (json.decay) {
                    this._light.decay = json.decay
                }

                if (json.angle) {
                    this._light.angle = json.angle
                }

                if (json.penumbra) {
                    this._light.penumbra = json.penumbra
                }

                resolve(this)
            })
        })
    }

    public data() : any {
        const data:any = super.data();

        data.target = [this._light.target.position.x, this._light.target.position.y, this._light.target.position.z];
        data.distance = this._light.distance;
        data.power = this._light.power;
        data.decay = this._light.decay;
        data.angle = this._light.angle;
        data.penumbra = this._light.penumbra;

        return data
    }

    get description() : string {
        return "Point light"
    }
}