export enum DebugLevel {
    None = 0,
    Performance = 1 << 0,
    Message = 1 << 1,
    Error = (1 << 2) | Message,
    Warning = (1 << 3) | Error,
    Verbose = (1 << 4) | Warning,
    All = ~(~0 << 5)
}

(window as any).GlobalLogLevel = (window as any).hasOwnProperty("GlobalLogLevel") ? (window as any).GlobalLogLevel : DebugLevel.Warning;
export let GlobalLogLevel:DebugLevel = (window as any).GlobalLogLevel;

export const LogLevel = (value:DebugLevel)=>{
    GlobalLogLevel = value;
}

if (!(window as any).hasOwnProperty("GlobalLogLevel")) {
    LogLevel(DebugLevel.Warning);
}

const _log = (level:DebugLevel, logger:any, ...args: any[])=>{
    return level === (GlobalLogLevel & level) ? logger(...args) : ()=>{};
};

export const PerformanceLog = (...args: any[]) => _log(DebugLevel.Performance, console.log, ...args);
export const MessageLog = (...args: any[]) => _log(DebugLevel.Error, console.log, ...args);
export const VerboseLog = (...args: any[]) => _log(DebugLevel.Verbose, console.log, ...args);
export const WarningLog = (...args: any[]) => _log(DebugLevel.Warning, console.warn, ...args);
export const ErrorLog = (...args: any[]) => _log(DebugLevel.Error, console.error, ...args);

const _ElapsedString = (ms:number)=>{
    return `${ms.toFixed(2)} ms`
}

export const Elapsed = (start:number)=>{
    return _ElapsedString(performance.now() - start)
}

export const PerformanceLogger = (label:string, callback:(start:number)=>void, handleMessage:(ms:number)=>void=(ms:number)=>{
    PerformanceLog( `${label} took ${_ElapsedString(ms)}`);
})=>{
    const start = performance.now();
    callback(start);
    handleMessage(performance.now() - start);
}
