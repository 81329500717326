import {CBARObject} from "./CBARObject";
import {CBAREvent, CBAREventType, CBARMouseEvent} from "../CBARTypes";
import {CBARObject3D} from "./CBARObject3D";
import * as THREE from "three";


export abstract class CBARObject3DContainer<T> extends CBARObject<CBARObject3DContainer<T>> {

    protected abstract getObject3D(event: CBAREvent) : CBARObject3D<T>|undefined

    public abstract render(event:CBAREvent) : void;

    private _draggingObject?:CBARObject3D<T>;

    private handleDragging(event: CBAREvent, obj?:CBARObject3D<T>) {
        const dragEvent = {...event};

        if (event.type == CBAREventType.TouchMove) {
            if (this._draggingObject) {
                dragEvent.type = CBAREventType.DragMove;
                this._handleEvent(dragEvent, this._draggingObject);
            }
        }
        else if (event.type == CBAREventType.TouchDown && obj) {
            this._draggingObject = obj;
            dragEvent.type = CBAREventType.DragStart;
            this._handleEvent(dragEvent, this._draggingObject);

        } else if ((event.type == CBAREventType.TouchUp || event.type == CBAREventType.TouchLeave || event.type == CBAREventType.MouseOut) && this._draggingObject) {
            dragEvent.type = CBAREventType.DragEnd;
            this._handleEvent(dragEvent, this._draggingObject);
            this._draggingObject = undefined;
        }
    }

    public handleEvent(event: CBAREvent) {
        const obj = this.getObject3D(event);

        this.handleDragging(event, obj);

        if (obj && obj.receivesEvents) {
            this._handleEvent(event, obj)
        }
    }

    private _handleEvent(event: CBAREvent, obj:CBARObject3D<any>) {
        obj.handleEvent(event)
    }

    abstract sceneLoaded():void

    abstract getEventObjects(eventType:CBAREventType) : THREE.Object3D[]
}