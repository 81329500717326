import * as THREE from "three";
import {CBARTexture} from "./CBARTexture";
import {Texture} from "three";
import {CBARMaterial, CBARMaterialProperty, CBARTextureType} from "./CBARMaterial";
import {CBARContext} from "../CBARContext";

export class CBARStandardMaterial extends CBARMaterial<CBARStandardMaterial> {

    public constructor(context:CBARContext, reference?: CBARStandardMaterial) {
        super(context, reference);

        this.setThreeMaterial(new THREE.MeshStandardMaterial({transparent: true}))
    }

    public get threeMaterial() : THREE.MeshStandardMaterial {
        return this._material as THREE.MeshStandardMaterial
    }

    protected setTextureMap(texture:CBARTexture) : Texture | null {

        super.setTextureMap(texture);

        switch (texture.type) {
            case CBARTextureType.albedo:
                this.threeMaterial.map = texture.threeTexture;
                return this.threeMaterial.map;

            case CBARTextureType.bump:
                this.threeMaterial.bumpMap = texture.threeTexture;
                return this.threeMaterial.bumpMap;

            case CBARTextureType.normals:
                this.threeMaterial.normalMap = texture.threeTexture;
                return this.threeMaterial.normalMap;

            case CBARTextureType.roughness:
                this.threeMaterial.roughnessMap = texture.threeTexture;
                return this.threeMaterial.roughnessMap;

            case CBARTextureType.lightMap:
                this.threeMaterial.lightMap = texture.threeTexture;
                return this.threeMaterial.lightMap;

            case CBARTextureType.emissive:
                this.threeMaterial.emissiveMap = texture.threeTexture;
                return this.threeMaterial.emissiveMap;

            case CBARTextureType.displacement:
                this.threeMaterial.displacementMap = texture.threeTexture;
                return this.threeMaterial.displacementMap;

            case CBARTextureType.metalness:
                this.threeMaterial.metalnessMap = texture.threeTexture;
                return this.threeMaterial.metalnessMap;

            case CBARTextureType.alpha:
                this.threeMaterial.alphaMap = texture.threeTexture;
                return this.threeMaterial.alphaMap;

            case CBARTextureType.environment:
                this.threeMaterial.envMap = texture.threeTexture;
                return this.threeMaterial.envMap;

            default:
                console.warn(`Unhandled material texture ${texture.type}`)

        }

        return null
    }

    public setMaterialProperty(name:CBARMaterialProperty, value:any) {

        super.setMaterialProperty(name, value);
        //console.log(`Set material property ${name} to ${value}`);

        switch (name) {
            case CBARMaterialProperty.color:
                this.threeMaterial.color = new THREE.Color(value);
                break;
            case CBARMaterialProperty.opacity:
                this.threeMaterial.opacity = value;
                break;
            case CBARMaterialProperty.roughnessValue:
                this.threeMaterial.roughness = value;
                break;
            case CBARMaterialProperty.metalnessValue:
                this.threeMaterial.metalness = value;
                break;
            case CBARMaterialProperty.bumpScaleValue:
                this.threeMaterial.bumpScale = value;
                break;
            case CBARMaterialProperty.lightMapIntensity:
                this.threeMaterial.lightMapIntensity = value;
                break;
            case CBARMaterialProperty.aoMapIntensity:
                this.threeMaterial.aoMapIntensity = value;
                break;
            case CBARMaterialProperty.emissiveIntensity:
                this.threeMaterial.emissiveIntensity = value;
                break;
            case CBARMaterialProperty.displacementScale:
                this.threeMaterial.displacementScale = value;
                break;
            case CBARMaterialProperty.displacementBias:
                this.threeMaterial.displacementBias = value;
                break;
            case CBARMaterialProperty.envMapIntensity:
                this.threeMaterial.envMapIntensity = value;
                break;
            case CBARMaterialProperty.refractionRatio:
                this.threeMaterial.refractionRatio = value;
                break;

            default:
                console.warn(`Unhandled material property ${name}`)
        }

        this.threeMaterial.needsUpdate = true;

        return null
    }

    public getMaterialProperty(name:CBARMaterialProperty) {

        switch (name) {
            case CBARMaterialProperty.color:
                return this.threeMaterial.color.getHex();
            case CBARMaterialProperty.opacity:
                return this.threeMaterial.opacity;
            case CBARMaterialProperty.roughnessValue:
                return this.threeMaterial.roughness;
            case CBARMaterialProperty.metalnessValue:
                return this.threeMaterial.metalness;
            case CBARMaterialProperty.bumpScaleValue:
                return this.threeMaterial.bumpScale;
            case CBARMaterialProperty.lightMapIntensity:
                return this.threeMaterial.lightMapIntensity;
            case CBARMaterialProperty.aoMapIntensity:
                return this.threeMaterial.aoMapIntensity;
            case CBARMaterialProperty.emissiveIntensity:
                return this.threeMaterial.emissiveIntensity;
            case CBARMaterialProperty.displacementScale:
                return this.threeMaterial.displacementScale;
            case CBARMaterialProperty.displacementBias:
                return this.threeMaterial.displacementBias;
            case CBARMaterialProperty.envMapIntensity:
                return this.threeMaterial.envMapIntensity;
            case CBARMaterialProperty.refractionRatio:
                return this.threeMaterial.refractionRatio
        }

        return super.getMaterialProperty(name)
    }

    public get textures() {
        const textures:THREE.Texture[] = [];
        if (this.threeMaterial.map) {
            textures.push(this.threeMaterial.map);
        }
        if (this.threeMaterial.normalMap) {
            textures.push(this.threeMaterial.normalMap);
        }
        if (this.threeMaterial.roughnessMap) {
            textures.push(this.threeMaterial.roughnessMap);
        }
        return textures;
    }

    public get repeat() : THREE.Vector2 {
        if (this.threeMaterial.map) {
            return this.threeMaterial.map.repeat;
        }
        return new THREE.Vector2(1,1);
    }

    public set repeat(dimensions:THREE.Vector2) {
        this.textures.forEach(texture=>{
            texture.wrapS = this.wrappingX;
            texture.wrapT = this.wrappingY;
            texture.repeat.set(dimensions.x, dimensions.y);
        })
    }

    get description() : string {
        return "Standard Material"
    }

    //cloning materials reusing uniforms
    //https://github.com/mrdoob/three.js/issues/6010
    protected cloneObject() : CBARStandardMaterial {
        let copy = new CBARStandardMaterial(this.context, this);
        copy.repeat = this.repeat;
        return copy
    }
}