import {Tensor} from "gammacv";
import {CBARCameraResolution} from "../../CBARContext";

export const isPortrait = window.outerWidth < window.outerHeight;

const STANDARD_VIDEO_ASPECT = 16/9;

const createCanvas = () => {
    try {
        return document.createElement('canvas');
    } catch (error) {
        return new OffscreenCanvas(1, 1);
    }
}

const createVideoElement = () => {
    return document.createElement('video');
}

const getWidth = (r:number, h:number) => {
    return r * h;
}

const getHeight = (r:number, w:number) => {
    return w / r;
}

type CanvasContext = CanvasRenderingContext2D|OffscreenCanvasRenderingContext2D|null
type CanvasSource = Tensor|'uint8'|'uint8c'|'float32'

navigator.getUserMedia = navigator.getUserMedia
    || (navigator as any).webkitGetUserMedia
    || (navigator as any).mozGetUserMedia
    || (navigator as any).msGetUserMedia
    || (navigator as any).oGetUserMedia;

function getMaxAvailableSize(r:number, maxWidth:number, maxHeight:number) {
    if (maxWidth) {
        const _height = getHeight(r, maxWidth);

        if (_height <= maxHeight) {
            return {
                width: maxWidth,
                height: _height,
            };
        }
    }

    return {
        width: getWidth(r, maxHeight),
        height: maxHeight,
    };
}

function getMinAvailableSize(r:number, minWidth:number, minHeight:number) {
    if (minWidth) {
        const _height = getHeight(r, minWidth);

        if (_height > minHeight) {
            return {
                width: minWidth,
                height: _height,
            };
        }
    }

    return {
        width: getWidth(r, minHeight),
        height: minHeight,
    };
}

export type CBARVideoSettings = {
    deviceId:string
    sourceWidth:number
    sourceHeight:number
    displayWidth:number,
    displayHeight:number
}

export default class CBARVideoCapture {

    public readonly video
    private readonly canvas
    private readonly canvasCtx:CanvasContext
    private readonly sourceCanvas
    private readonly sourceCanvasCtx:CanvasContext

    private width
    private height
    private sourceWidth
    private sourceHeight
    private track:MediaStreamTrack|null

    private sourceMinWidth = 0
    private sourceMinHeight = 0

    constructor(width:number, height:number) {
        this.video = createVideoElement();
        this.video.muted = true;
        this.video.playsInline = true;
        this.canvas = createCanvas();
        this.canvasCtx = this.canvas.getContext('2d');
        this.sourceCanvas = createCanvas();
        this.sourceCanvasCtx = this.sourceCanvas.getContext('2d');

        this.width = width;
        this.height = height;

        this.sourceWidth = width;
        this.sourceHeight = height;

        this.setSize(width, height);
        this.track = null;
    }

    setSize(width:number, height:number) {
        this.width = width;
        this.height = height;
        this.canvas.width = width;
        this.canvas.height = height;
        this.sourceCanvas.width = width;
        this.sourceCanvas.height = height;
        this.sourceMinWidth = width;
        this.sourceMinHeight = height;
    }

    setSourceSize(width:number, height:number) {
        const scaledSize = getMinAvailableSize(width / height, this.width, this.height);
        const size = getMaxAvailableSize(this.width / this.height, width, height);
        const scaledMinSize = getMinAvailableSize(width / height, size.width, size.height);

        this.sourceMinWidth = scaledSize.width;
        this.sourceMinHeight = scaledSize.height;

        this.sourceWidth = scaledMinSize.width;
        this.sourceHeight = scaledMinSize.height;

        this.sourceCanvas.width = size.width;
        this.sourceCanvas.height = size.height;
    }

    started = false

    start(resolution:CBARCameraResolution, facing:string|undefined) {

        console.log("Using recommended res", resolution);

        this.started = true;
        const cfg:MediaStreamConstraints = {
            video: {
                width: { ideal: resolution > 0 ? resolution : CBARCameraResolution.Default },
                height: { ideal: (resolution > 0 ? resolution : CBARCameraResolution.Default) / STANDARD_VIDEO_ASPECT },
                facingMode:facing,
            },
        };

        const ua = navigator.userAgent;

        if (/android/i.test(ua) && isPortrait) {
            // @ts-ignore
            cfg.video.aspectRatio.exact = 1 / cfg.video.aspectRatio.exact;
        }

        return navigator.mediaDevices.getUserMedia(cfg).then((stream) => {
            if (stream) {
                const tracks = stream.getTracks();

                if (!this.started) {
                    tracks.forEach(t => t.stop());
                    return null;
                }
                if ('srcObject' in this.video) {
                    this.video.srcObject = stream;
                } else {
                    (this.video as any).src = window.URL.createObjectURL(stream);
                }

                this.track = tracks[0];

                console.log("Track info", this.track.getSettings())

                return this.video.play()
                    .then(() => this.setSourceSize(this.video.videoWidth, this.video.videoHeight));
            }
            throw new Error('getUserMedia not found or no stream was created');
        });
    }

    public stop() {
        this.started = false;
        if (this.track) {
            this.track.stop();
            this.track = null;
        }
    }

    public drawImage(ctx:CanvasContext, w:number, h:number, ow:number, oh:number) {
        ctx?.drawImage(
            this.video,
            (ow - w) / -2,
            (oh - h) / -2,
            ow,
            oh,
        );
    }

    public getImageBuffer(
        type:CanvasSource,
        ctx = this.canvasCtx,
        width = this.width,
        height = this.height,
        x = 0,
        y = 0,
        w = width,
        h = height,
        originW = this.sourceMinWidth,
        originH = this.sourceMinHeight,
    ) {
        if (!ctx) return;
        this.drawImage(ctx, w, h, originW, originH);
        const imgData = ctx.getImageData(x, y, w, h);

        if (type instanceof Tensor) {
            (type as any).data.set(imgData.data);
            return type;
        }

        switch (type) {
            case 'uint8': {
                return new Uint8Array(imgData.data);
            }
            case 'uint8c': {
                return imgData.data;
            }
            case 'float32': {
                return new Float32Array(imgData.data);
            }
            default: {
                return imgData;
            }
        }
    }

    public getImageBufferTo(
        type:CanvasSource,
        ctx = this.canvasCtx!,
        width = this.width,
        height = this.height,
        x = 0,
        y = 0,
        w = width,
        h = height,
        to:any,
    ) {
        ctx.drawImage(
            this.video,
            (this.sourceWidth - this.width) / -2,
            (this.sourceHeight - this.height) / -2,
            this.sourceWidth,
            this.sourceHeight,
        );
        const imgData = ctx.getImageData(x, y, w, h);

        to.data = imgData.data.buffer;
    }

    public getSourceImageBuffer(type:CanvasSource, x:number, y:number, w:number, h:number) {
        return this.getImageBuffer(
            type,
            this.sourceCanvasCtx,
            this.sourceCanvas.width,
            this.sourceCanvas.height,
            x,
            y,
            w,
            h,
            this.sourceWidth,
            this.sourceHeight,
        );
    }

    public get resolution() : [number, number] | undefined {
        if (this.track) {
            const width = this.track.getSettings().width;
            const height = this.track.getSettings().height;
            if (width && height) {
                return [width, height];
            }
        }
        return undefined;
    }
}