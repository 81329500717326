import {SwatchItem} from "./SwatchItem";
import {ProductBrand} from "./ProductBrand";
import {CBARAssetType, CBARSurfaceType} from "../CBARTypes";
import {TiledGridType} from "../assets";
import {confineToEnum} from "../internal/Utils";
import * as THREE from "three";

export class MonitoredArray<T> extends Array<T> {

    constructor(onChange:()=>void, ...data: T[]) {
        super(...data);

        this.push = (...items: T[]) => {
            const result = Array.prototype.push.apply(this, items);
            onChange();
            return result
        };

        Object.setPrototypeOf(this, Array.prototype);
    }
}

export abstract class DataItem implements SwatchItem {

    constructor(public dataUrl?:string) {

    }

    public fetch() {
        if (this.dataUrl) {
            const promise = fetch(this.dataUrl).then(resp => resp.json()).then(json=>{
                this.load(json);
                this.needsUpdate();
                this.dataUrl = undefined;
                return this
            });
            return promise;
        }
    }

    public load(json:any) {
        this.json = json;
        if (json.hasOwnProperty("dataUrl")) {
            this.dataUrl = json.dataUrl
        }
        if (json.hasOwnProperty("description")) {
            this.description = json.description;
        }
        if (json.hasOwnProperty("numChildren")) {
            this.numChildren = json.numChildren;
        }
        if (json.hasOwnProperty("name")) {
            this.name = json.name
        }
        if (json.hasOwnProperty("enabled")) {
            this.enabled = json.enabled
        }
        if (json.hasOwnProperty("code")) {
            this.code = json.code
        }
        if (json.hasOwnProperty("displayName")) {
            this.displayName = json.displayName
        }
        if (json.hasOwnProperty("assetTypes")) {
            this._assetTypes = confineToEnum(CBARAssetType, json.assetTypes)
        }
        if (json.hasOwnProperty("surfaceTypes")) {
            this._surfaceTypes = confineToEnum(CBARSurfaceType, json.surfaceTypes)
        }
        if (json.hasOwnProperty("patterns")) {
            this._patterns = json.patterns
        }
        if (json.hasOwnProperty("thumbnail")) {
            this.thumbnail = json.thumbnail
        }
        if (json.hasOwnProperty("color")) {
            this.color = json.color
        }
        if (json.hasOwnProperty("metaData")) {
            this.metaData = json.metaData
        }
        if (json.hasOwnProperty("orderIndex")) {
            this.orderIndex = json.orderIndex
        }
        if (json.hasOwnProperty("isDefault")) {
            this.isDefault = json.isDefault
        }
    }

    public abstract get parent():DataItem|undefined

    public abstract get brand():ProductBrand

    public get hasColumns(): boolean {
        return false
    }

    public get children(): DataItem[] {
        return []
    }

    public key = THREE.MathUtils.generateUUID();
    public enabled:boolean = true;
    protected _json?: any;
    public get json(): any {
        return this._json;
    }

    public set json(value: any) {
        this._json = value;
    }
    public name?:string;
    public code?:string;
    public displayName?:string;
    public description?:string;
    public numChildren?:number;
    public thumbnail?:string;
    public color?:string;
    public orderIndex = 0;
    public isDefault = false;
    public metaData:any;

    private _assetTypes:CBARAssetType[] = [];

    public get assetTypes() : CBARAssetType[] {
        if (this._assetTypes.length) {
            return this._assetTypes;
        } else if (this.parent) {
            return this.parent.assetTypes
        }
        return []
    }

    public set assetTypes(value:CBARAssetType[]) {
        this._assetTypes = value
    }

    private _surfaceTypes:CBARSurfaceType[] = [];

    public get surfaceTypes() : CBARSurfaceType[] {
        if (this._surfaceTypes.length) {
            return this._surfaceTypes;
        } else if (this.parent) {
            return this.parent.surfaceTypes
        }
        return []
    }

    public set surfaceTypes(value:CBARSurfaceType[]) {
        this._surfaceTypes = value
    }

    private _patterns:TiledGridType[] | any = [];

    public get patterns() : TiledGridType[] | any[] {
        if (this._patterns.length) {
            return this._patterns;
        } else if (this.parent) {
            return this.parent.patterns
        }
        return []
    }

    private _selected = false;
    public get selected() {
        return this._selected
    }

    public set selected(value) {
        if (this._selected === value) return;

        if (value && this.parent) {
            const selectedChild = this.parent.children.find(i=>i.selected);
            if (selectedChild) {
                selectedChild.selected = false
            }
        }
        this._selected = value
    }

    private _wasUpdatedTimeout = 0;
    protected needsUpdate() {
        if (this.onUpdate) {
            if (!this._wasUpdatedTimeout) {
                this._wasUpdatedTimeout = window.setTimeout(()=>{
                    this._wasUpdatedTimeout = 0;
                    if (this.onUpdate) {
                        this.onUpdate(this)
                    }
                }, 500)
            }
        }
        if (this.parent && this.parent instanceof DataItem) {
            (this.parent as DataItem).needsUpdate()
        }
    }

    public onUpdate?:(item:DataItem)=>void
}