import * as gm from "gammacv";
import {CBARCameraFacing, CBARCameraResolution, CBARContext, CBARFeatureTracking} from "../../CBARContext";
import {CBARProcessNode} from "./CBARProcessNode";
import {CBARFrame} from "../CBARFrame";
import {DeferredExecutor} from "../Utils";
import {CBARLineFinderNode} from "./CBARLineFinderNode";
import {CBARClassifierNode} from "./CBARClassifierNode";
import {getConfig} from "../../../backend";
import {CBARPageFinderNode, NUM_CLUSTERS} from "./CBARPageFinderNode";
import CBARVideoCapture from "./CBARVideoCapture";
import {Rect} from "mirada";

export enum CBARPipelineTask {
    HoughLines,
    OpticalFlow
}

type TaskHandler<T> = (data:T)=>boolean

const PROCESSING_SIZE = 640;

export class CBARPipeline {

    public pipeline:gm.InputType;
    public session?:gm.Session;
    private stream:CBARVideoCapture;

    public readonly input:gm.Tensor;

    public readonly pcLinesOutput?:gm.Tensor|null;

    public readonly greyscaleOp:gm.Operation|null;
    public readonly greyscaleOutput:gm.Tensor|null;
    public readonly greyscaleCanvas:HTMLCanvasElement;

    public readonly edgesOp?:gm.Operation|null;
    public readonly edgesOutput?:gm.Tensor|null;

    public readonly colorSegmentationOp?:gm.Operation|null;
    public readonly colorSegmentationOutput?:gm.Tensor|null;
    public readonly colorSegmentationCanvas?:HTMLCanvasElement;

    private readonly inputWidth:number;
    private readonly inputHeight:number;

    constructor(public context:CBARContext,
                protected readonly displayCanvas:HTMLCanvasElement,
                public readonly tracking:CBARFeatureTracking=CBARFeatureTracking.None) {

        const config = getConfig();
        this._nodes = [];

        this.inputWidth = displayCanvas.width;
        this.inputHeight = displayCanvas.height;

        this.stream = new CBARVideoCapture(this.inputWidth, this.inputHeight);
        this.input = new gm.Tensor('uint8', [this.inputHeight, this.inputWidth, 4]);

        this.pipeline = this.input;

        this.displayCanvas.width = this.inputWidth;
        this.displayCanvas.height = this.inputHeight;

        const processingScale = this.inputWidth > this.inputHeight ? PROCESSING_SIZE / this.inputWidth : PROCESSING_SIZE / this.inputHeight;

        //reduce to VGA for speed
        if (processingScale < 1.0) {
            this.pipeline = gm.resize(this.pipeline,  Math.floor(processingScale * this.input.shape[1]), Math.floor(processingScale * this.input.shape[0]));
        }

        this.greyscaleOp = gm.grayscale(this.pipeline);
        this.greyscaleOutput = gm.tensorFrom(this.greyscaleOp);
        this.greyscaleCanvas = gm.canvasCreate(this.greyscaleOutput!.shape[1], this.greyscaleOutput!.shape[0]);

        console.log("Display size", this.inputWidth, this.inputHeight);
        console.log("Processing size", this.greyscaleOutput!.shape[1], this.greyscaleOutput!.shape[0]);
        console.log("Video size", this.resolution);

        this.pipeline = this.greyscaleOp;

        if (this.tracking !== CBARFeatureTracking.None) {

            if (this.tracking === CBARFeatureTracking.Page || this.tracking === CBARFeatureTracking.Card) {
                this.colorSegmentationOp = gm.colorSegmentation(this.greyscaleOp, NUM_CLUSTERS);
                this.colorSegmentationOutput = gm.tensorFrom(this.colorSegmentationOp);
                this.colorSegmentationCanvas = gm.canvasCreate(this.colorSegmentationOutput!.shape[1], this.colorSegmentationOutput!.shape[0]);
                this.pipeline = this.colorSegmentationOp;
                
                this._nodes.push(new CBARPageFinderNode(this));
            }

            if (this.tracking === CBARFeatureTracking.World) {
                this.pipeline = gm.gaussianBlur(this.pipeline, 3, 2);
                this.pipeline = gm.sobelOperator(this.pipeline);

                this.edgesOp = gm.cannyEdges(this.pipeline, 0.1, 0.3);
                this.edgesOutput = gm.tensorFrom(this.edgesOp);
                this.pipeline = this.edgesOp;

                //find lines. tune here: https://gammacv.com/examples/pc_lines
                const layersCount = 2;
                const dStep = 2;
                const dCoeficient = 2.0;

                this.pipeline = gm.pcLinesTransform(this.pipeline, dStep);
                this.pipeline = gm.pcLinesEnhance(this.pipeline);

                //reduce:
                this.pipeline = gm.pcLinesReduceMax(this.pipeline, dCoeficient, 0);

                for (let i = 0; i < layersCount; i += 1) {
                    this.pipeline = gm.pcLinesReduceMax(this.pipeline, dCoeficient, 1);
                }

                this.pcLinesOutput = gm.tensorFrom(this.pipeline);
            }

            if (this.tracking === CBARFeatureTracking.World) {
                this._nodes.push(new CBARLineFinderNode(this));
            }
            else if (config.classifierPath && (this.tracking === CBARFeatureTracking.Classifier || this.tracking === CBARFeatureTracking.Face)) {
                this._nodes.push(new CBARClassifierNode(this, {
                    classifierPath:config.classifierPath,
                    maxRegions:config.classifierMaxRegions,
                    updateMS:config.classifierUpdateFrequency,
                    minSize:config.classifierMinSize ? new cv.Size(config.classifierMinSize[0], config.classifierMinSize[1]) : undefined,
                    maxSize:config.classifierMaxSize ? new cv.Size(config.classifierMaxSize[0], config.classifierMaxSize[1]) : undefined,
                }));
            }
        }

        this.session = new gm.Session()
        this.session.init(this.pipeline);
    }

    public get downsample() {
        return this.input.shape[1] / this.greyscaleOutput!.shape[1];
    }

    public captureRawOutput(roi?:Rect) {
        if (this.stream.resolution && this.stream.video) {
            console.log("Capturing from", this.stream.resolution);
            const canvas = gm.canvasCreate(this.stream.resolution[0], this.stream.resolution[1]);
            const context = canvas.getContext("2d");
            if (context) {
                context.drawImage(this.stream.video, 0, 0, this.stream.resolution[0], this.stream.resolution[1]);
                return canvas;
            }
        }
        return undefined;
    }

    public get nodes() {
        return this._nodes;
    }

    protected frameIndex = 0;
    public debugEnabled = true;

    protected tasks:DeferredExecutor<any>[] = []

    public requestTask<T>(task:CBARPipelineTask) {
        return new Promise<T>((resolve, reject) => {
            this.tasks[task] = {resolve, reject}
        });
    }

    private _subscriptions:{ [task: number]: TaskHandler<any>[]} = {};
    public subscribeToTask<T>(task:CBARPipelineTask, handler:TaskHandler<T>) {
        if (!this._subscriptions[task]) {
            this._subscriptions[task] = [];
        }
        this._subscriptions[task].push(handler);
    }

    public completeTask<T>(task:CBARPipelineTask, executor:()=>T) {
        const item = this.tasks[task];
        const subscriptions = this._subscriptions[task];
        if (item || subscriptions) {
            const result = executor();
            subscriptions?.forEach(handler=>handler(result));
            if (item) {
                item.resolve(result);
                delete this.tasks[task];
            }
        }
    }

    public update() {
        if (!this.session || !this.stream || !this.input) {
            return
        }

        const frame = new CBARFrame(this, this.frameIndex);

        const activeNodes = this._nodes.filter(n=>n.enabled);

        //load in source image:
        this.stream.getImageBuffer(this.input);

        gm.canvasFromTensor(this.displayCanvas, this.input as gm.Tensor);

        //todo: hack: DRAW is intentional. For some reason mobile devices need whatever happens to the canvas in a draw command:
        gm.canvasDrawCircle(this.displayCanvas, [0,0], 0, 'rgba(0, 0, 0, 0.1)');

        //process at each pipeline node
        activeNodes.forEach(n=>n.update(frame));

        frame.destroy();

        if (this.debugEnabled) {
            activeNodes.forEach(n=>n.debug(this.displayCanvas));
        }

        this.frameIndex += 1;
    }

    public resolution = CBARCameraResolution.Default;
    public facing = CBARCameraFacing.Default;

    public start(resolution:CBARCameraResolution=CBARCameraResolution.Default, facing=CBARCameraFacing.Default) {

        if (facing === CBARCameraFacing.Default) {
            facing = this.tracking === CBARFeatureTracking.Face ? CBARCameraFacing.User : CBARCameraFacing.Environment;
        }

        if (this.tracking == CBARFeatureTracking.Page || this.tracking == CBARFeatureTracking.Card) {
            resolution = CBARCameraResolution.Maximum;
        }

        this.facing = facing;
        this.resolution = resolution;

        console.log("Feature tracking is " + this.tracking + " resolution: " + resolution);
        return this.stream.start(this.resolution, this.facing);
    }

    private readonly _nodes:CBARProcessNode[] = [];

    public release() {
        this._nodes.forEach(n=>n.destroy());

        this.stream.stop();
        this.session?.destroy();
        this.session = undefined;
        this.input.release();
    }
}