import {TextureConfig} from "cambrian-base";
import {DataItem} from "./DataItem";
import {ProductItem} from "./ProductItem";
import {ProductBrand} from "./ProductBrand";

export class ProductTexture extends DataItem {

    public constructor(private _parent:ProductItem) {
        super()
    }

    public albedoPath?:string;
    public normalsPath?:string;
    public roughnessPath?:string;
    public bumpMapPath?:string;
    public specularPath?:string;

    get brand(): ProductBrand {
        return this._parent.brand;
    }

    get parent(): DataItem | undefined {
        return this._parent;
    }

    protected get getChildren(): DataItem[] {
        return []
    }

    public load(json:TextureConfig) {
        this.albedoPath = json.albedo;
        this.normalsPath = json.normals;
        this.bumpMapPath = json.bumpMap;
        this.roughnessPath = json.roughness;
        this.specularPath = json.specular;
    }

    public get json() {
        const json:TextureConfig = {};
        if (this.albedoPath) {
            json.albedo = this.albedoPath
        }
        if (this.normalsPath) {
            json.normals = this.normalsPath
        }
        if (this.roughnessPath) {
            json.roughness = this.roughnessPath
        }
        if (this.bumpMapPath) {
            json.bumpMap = this.bumpMapPath
        }
        return json
    }
}