import * as THREE from "three";
import {CBARLightAsset, CBARLightAssetProperties} from "./CBARLightAsset";
import {CBARContext} from "../../CBARContext";
import {CBARVector} from "../../CBARTypes";

interface CBARDirectionalLightProperties extends CBARLightAssetProperties {
    target?:CBARVector
}

export class CBARDirectionalLight extends CBARLightAsset {

    constructor(context:CBARContext) {
        super(context);
        this._light.target = this._target;
        this._light.castShadow = true;
        this.setRenderObject(this.light)
    }

    protected _light = new THREE.DirectionalLight( 0xffffff, 1);
    protected _target = new THREE.Object3D();

    public get light():THREE.DirectionalLight {
        return this._light
    }

    load(basePath:string|undefined, json:CBARDirectionalLightProperties) : Promise<CBARDirectionalLight> {

        if (json.target) {
            this._light.target.position.set(json.target[0], json.target[1], json.target[2])
        }

        return new Promise<CBARDirectionalLight>((resolve) => {
            return super.load(basePath, json).then(()=>{
                resolve(this)
            })
        })
    }

    public data() : any {
        const data:any = super.data();

        data.target = [this._light.target.position.x, this._light.target.position.y, this._light.target.position.z];

        return data
    }

    get description() : string {
        return "Directional light"
    }
}