import {CBARProcessNode} from "./CBARProcessNode";
import {CBARFrame} from "../CBARFrame";
import {Mat, Point} from "mirada";
import {CBARPipeline, CBARPipelineTask} from "./CBARPipeline";
import * as gm from "gammacv";
import {CBARDebug} from "../../CBARContext";
import {getConfig} from "../../../backend";

type OpticalFlowPoint = {
    point:Point
    frameTime:number
    frameIndex:number
}

let OpticalFlowFeature_index = 0;
export class OpticalFlowFeature {
    constructor(public previousPoint:OpticalFlowPoint, public currentPoint:OpticalFlowPoint, public cluster:OpticalFlowCluster|undefined) {

    }

    public readonly id = OpticalFlowFeature_index++;
    public status?:number;
    public error?:number;

    public get position() {
        return this.currentPoint;
    }

    public get dx() {
        return this.currentPoint.point.x - this.previousPoint.point.x;
    }

    public get dy() {
        return this.currentPoint.point.y - this.previousPoint.point.y;
    }

    public get velocity() {
        const dt = this.currentPoint.frameIndex - this.previousPoint.frameIndex;
        return new cv.Point(this.dx/dt,this.dy/dt);
    }
}

let OpticalFlowCluster_index = 0;

export class OpticalFlowCluster {

    constructor(public roi:gm.Rect, public qualityLevel:number, public minFeatures=15) {

    }

    public readonly id = OpticalFlowCluster_index++;

    public features:OpticalFlowFeature[] = []
}

export type OpticalFlowResult = {
    points:OpticalFlowFeature[]
    removed:OpticalFlowFeature[]
}

export type OpticalFlowConfig = {
    maxPoints:number,
    qualityLevel:number,
    minDistance:number,
    windowSize:number,
    oflowStep:number
}

const _default:OpticalFlowConfig = {
    maxPoints:100,
    qualityLevel:0.1,
    minDistance:5,
    windowSize:5,
    oflowStep:1
}

export class CBAROpticalFlowNode extends CBARProcessNode {

    private _prevFrame?:CBARFrame;
    private _features:OpticalFlowFeature[] = [];
    private _clusters:OpticalFlowCluster[] = [];
    private _gotLastFeaturesIndex = 0;

    constructor(context:CBARPipeline, _config?:OpticalFlowConfig) {
        super(context)

        this.config = {..._default, ..._config};
    }

    public config:OpticalFlowConfig

    public trackRegion(cluster:OpticalFlowCluster) {
        this._clusters[cluster.id] = cluster;
        return cluster;
    }

    public untrackRegion(cluster:OpticalFlowCluster) {
        this._features = this._features.filter(f=>f.cluster !== cluster);
        delete this._clusters[cluster.id];
    }

    public get trackedRegions() {
        return this._clusters;
    }

    private getNewFeatures(frame:CBARFrame, numFeatures:number, cluster?:OpticalFlowCluster) : OpticalFlowFeature[] {

        let mask:Mat;

        //define valid regions:
        if (cluster && cluster.roi.area) {
            //todo:need to use polylines/fill here since it's (gm.Rect) a four point area not really a 2D ROI
            mask = cv.Mat.zeros(frame.greyscaleImage.rows, frame.greyscaleImage.cols, cv.CV_8UC1);
            const pointA = new cv.Point(cluster.roi.ax / this.context.downsample,cluster.roi.ay / this.context.downsample);
            const pointB = new cv.Point(cluster.roi.cx  / this.context.downsample, cluster.roi.cy  / this.context.downsample);

            //cv.pointPolygonTest();
            cv.rectangle(mask, pointA, pointB, [255,0,0,255], cv.FILLED);
        } else {
            mask = cv.Mat.ones(frame.greyscaleImage.rows, frame.greyscaleImage.cols, cv.CV_8UC1);
        }

        this._features.forEach(f=>{
            cv.circle(mask, new cv.Point(f.currentPoint.point.x / frame.context.downsample, f.currentPoint.point.y / frame.context.downsample),
                this.config.minDistance, [0,0,0,255], cv.FILLED);
        });

        let newPoints = new cv.Mat();
        cv.goodFeaturesToTrack(frame.greyscaleImage, newPoints, numFeatures, cluster ? cluster.qualityLevel : this.config.qualityLevel, this.config.minDistance, mask, this.config.windowSize);

        const newFeatures:OpticalFlowFeature[] = [];

        for (let i=0; i<newPoints.rows; i+=2) {
            const point = new cv.Point(newPoints.data32F[i] * frame.context.downsample, newPoints.data32F[i+1] * frame.context.downsample);
            const oflowPoint = {point, frameIndex:frame.index, frameTime:frame.time};
            const feature = new OpticalFlowFeature(oflowPoint, oflowPoint, cluster);
            newFeatures.push(feature);
        }

        newPoints.delete();
        mask.delete();

        return newFeatures;
    }

    private updateFeatures(prevFrame:CBARFrame, nextFrame:CBARFrame) {

        let status = new cv.Mat();
        let err = new cv.Mat();
        let prevPoints = new cv.Mat(this._features.length, 2, cv.CV_32F);
        let nextPoints = new cv.Mat();

        for (let i=0, j=0; i<this._features.length; i++, j+=2) {
            const feature = this._features[i];
            prevPoints.data32F[j] = feature.currentPoint.point.x / nextFrame.context.downsample;
            prevPoints.data32F[j+1] = feature.currentPoint.point.y / nextFrame.context.downsample;
        }

        cv.calcOpticalFlowPyrLK(prevFrame.greyscaleImage, nextFrame.greyscaleImage, prevPoints, nextPoints, status, err);

        for (let i=0, j=0; i < status.rows; i++, j+=2) {
            this._features[i].status = status.data[i];
            this._features[i].error = err.data[i];
            this._features[i].previousPoint = this._features[i].currentPoint;
            const rescaledPoint = new cv.Point(nextPoints.data32F[j] * nextFrame.context.downsample, nextPoints.data32F[j+1] * nextFrame.context.downsample);
            this._features[i].currentPoint = {point: rescaledPoint, frameIndex:nextFrame.index, frameTime:nextFrame.time};
        }

        status.delete();
        err.delete();
        prevPoints.delete();
        nextPoints.delete();
    }

    update(frame:CBARFrame):void {
        if ((frame.index % this.config.oflowStep) === 1) {
            //todo:interpolate via kalman filter or other
            return;
        }

        const toGrab = Math.floor(this.config.maxPoints - this._features.length);
        const minToGrab = Math.floor(this.config.maxPoints / 5);
        if (toGrab > minToGrab && (frame.index - this._gotLastFeaturesIndex) > 10) {
            this._features = this._features.concat(this.getNewFeatures(frame, toGrab));
            this._gotLastFeaturesIndex = frame.index;
        }

        this._clusters.forEach(cluster=>{
            if (cluster.features.length < cluster.minFeatures) {
                const numNeeded = cluster.minFeatures - cluster.features.length;
                const features = this.getNewFeatures(frame, numNeeded * 2, cluster);
                cluster.features = cluster.features.concat(features);
                this._features = this._features.concat(features);
            }
        })

        if (this._prevFrame && this._features.length > 1) {
            this.updateFeatures(this._prevFrame, frame);
        }

        //remove bad features and send result;
        const goodFeatures = this._features.filter(f=>f.status);
        const removedFeatures = this._features.filter(f=>f.status===0);
        const trackedFeatures = goodFeatures.filter(f=>f.currentPoint.frameIndex > f.previousPoint.frameIndex)

        //if something has changed, update:
        if (trackedFeatures.length) {
            this.context.completeTask<OpticalFlowResult>(CBARPipelineTask.OpticalFlow, ()=>{
                return {points:trackedFeatures, removed:removedFeatures};
            })
        }

        this._features = goodFeatures;
        this._prevFrame?.destroy();
        this._prevFrame = frame.clone();
    }

    debug(canvas:HTMLCanvasElement):void {
        if (CBARDebug.OpticalFlow === (getConfig().debug & CBARDebug.OpticalFlow)) {
            this._features.forEach(feature=>{
                gm.canvasDrawCircle(canvas, [feature.currentPoint.point.x, feature.currentPoint.point.y], 2,  feature.cluster ? 'rgba(0, 255, 0, 1)' : 'rgba(255, 255, 0, 1)');
            })
        }
    }

    destroy() {
        this._prevFrame?.destroy()
    }
}