import * as THREE from "three";
import {CBARLightAsset, CBARLightAssetProperties} from "./CBARLightAsset";
import {CBARContext} from "../../CBARContext";

export class CBARAmbientLight extends CBARLightAsset {

    constructor(context:CBARContext) {
        super(context)

        this.setRenderObject(this.light)
    }

    protected _light = new THREE.AmbientLight()

    public get light():THREE.AmbientLight {
        return this._light
    }

    get description() : string {
        return "Ambient light"
    }

    load(basePath:string|undefined, json: CBARLightAssetProperties): Promise<CBARAmbientLight> {
        return new Promise<CBARAmbientLight>((resolve) => {
            return super.load(basePath, json).then(()=>{
                resolve(this)
            })
        })
    }
}