import {CBARObject3D, CBARObject3DProperties} from "./CBARObject3D";
import * as THREE from "three";
import {CBARMediaInfo} from "../internal";
import {CBARContext} from "../CBARContext";

export interface CBARCameraProperties extends CBARObject3DProperties {
    fov:number
}

export class CBARCameraIntrinsics extends CBARObject3D<CBARCameraIntrinsics> {

    constructor(context:CBARContext) {
        super(context);
        this.camera = context.gl.camera;
        this.setRenderObject(this.camera)
    }

    public camera:THREE.PerspectiveCamera;
    public hFov:number = 50;

    load(basePath:string|undefined, json:CBARCameraProperties) : Promise<CBARCameraIntrinsics> {
        const promises:any[] = [super.load(basePath, json)];

        if (json.hasOwnProperty('fov')) {
            this.hFov = json.fov
        }

        return new Promise<CBARCameraIntrinsics>((resolve, reject) => {
            Promise.all(promises).then(()=>{

                resolve(this)
            }).catch(error=>{
                reject(error)
            })
        })
    }

    public data() : any {
        const data:any = super.data();

        data.fov = this.hFov;

        return data
    }

    private _media?:CBARMediaInfo;

    public setMediaProperties(props:CBARMediaInfo) {
        this._media = props;
        if (this._media) {

            const vFov = 180 / Math.PI * 2 * Math.atan(Math.tan(Math.PI / 180 * this.hFov / 2) * this._media.height / this._media.width);
            this.camera.fov = vFov;
            this.camera.aspect = this._media.width / this._media.height;
            this.camera.updateProjectionMatrix();

            //console.log("Projection Matrix", this.camera.projectionMatrix);
            //console.log(`Camera aspect ratio: ${this.camera.aspect},  h-fov: ${this.hFov} v-fov: ${this.camera.fov}`)
        }
        this.needsUpdate()
    }

    get description() : string {
        return "Camera Intrinsics"
    }
}

