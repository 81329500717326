
import {CBARError} from "../CBARError";
import {CBARContext} from "../CBARContext";
import * as THREE from "three";

export abstract class CBARObject<T> {

    constructor(public context:CBARContext) {

    }

    private _id:string = THREE.MathUtils.generateUUID();

    public get id() : string {
        return this._id
    }

    public set id(id:string) {
        this._id = id
    }

    //standardized rejection:
    protected rejectPromise(reject:(error:CBARError)=>void, error:CBARError) {
        reject(error)
    }

    abstract load(basePath:string|undefined, json:any) : Promise<T>

    public unload() {}

    abstract data() : any

    abstract get description() : string
}

