import {Mat} from "mirada";
import {imShowOverlay} from "../CBARView";

export enum Labels {
    barrier = -1,
    uncertain = 0,
    nonMask = 127,
    mask = 255,
}

export class CBARImaging {

    static readonly barrierLabel = [Labels.barrier, Labels.barrier, Labels.barrier, Labels.barrier];
    static readonly nonMaskLabel = [Labels.nonMask, Labels.nonMask, Labels.nonMask, Labels.nonMask];
    static readonly maskLabel = [Labels.mask, Labels.mask, Labels.mask, Labels.mask];
    static readonly uncertainLabel = [Labels.uncertain, Labels.uncertain, Labels.uncertain, Labels.uncertain];

    public static generateWatershedMarkers(mask:Mat, changes:Mat, erase:boolean, distance=0.7) {
        const markers = new cv.Mat(mask.rows, mask.cols, cv.CV_32S);

        markers.setTo(this.nonMaskLabel);

        const changesCertain = new cv.Mat();
        cv.distanceTransform(mask, changesCertain, cv.DIST_L2, cv.DIST_MASK_5);
        cv.normalize(changesCertain, changesCertain, 255.0, 0, cv.NORM_MINMAX);
        cv.threshold(changesCertain, changesCertain,  255.0 - 255.0 * distance, 255, cv.THRESH_BINARY);
        changesCertain.convertTo(changesCertain, cv.CV_8U);

        if (erase) {
            markers.setTo(this.maskLabel, mask);
            markers.setTo(this.uncertainLabel, changes);
            markers.setTo(this.nonMaskLabel, changesCertain);
        } else {
            markers.setTo(this.uncertainLabel, changes);
            markers.setTo(this.maskLabel, mask);
            markers.setTo(this.maskLabel, changesCertain);
        }

        changesCertain.delete();

        return markers;
    }
}
