import * as React from "react"
import {createRef, useCallback, useEffect, useMemo, useRef, useState} from "react"
import {CBARMediaInfo, CBARMediaView} from "./internal";
import * as THREE from "three";
import {OrthographicCamera} from "three";
import {DeferredExecutor, INT_MAX, normalizedToScreen, overlayMask} from "./internal/Utils";
import {ResizeObserver as Polyfill} from '@juggle/resize-observer';
import {CBARContext, ZoomState, CBARReceiver, ImageExportOptions, isDrawingTool} from "./CBARContext";
import {ReactZoomPanPinchRef, TransformComponent, TransformWrapper} from "react-zoom-pan-pinch";
import {
    CBAREventType,
    CBARIntersection,
    CBARMode,
    CBARMouseEvent,
    CBARRenderContext,
    CBARSurfaceType,
    CBARToolMode
} from "./CBARTypes";
import {CBARObject3D, CBARScene, CBARSceneProperties, DRAW_RADIUS} from "./components";
import {CBARAmbientLight, CBARDirectionalLight} from "./assets";
import {CBARDebugView} from "./internal/CBARDebugView";
import {MessageLog} from "./internal/GlobalLogger";
import {Mat} from "mirada";

let staticDebugView:CBARDebugView|null;

let leftMouseButtonOnlyDown = false;

const setLeftButtonState = (e:any) => {
    leftMouseButtonOnlyDown = e.buttons === undefined
        ? e.which === 1
        : e.buttons === 1;
}

document.body.addEventListener("mousedown", setLeftButtonState);
document.body.addEventListener("mouseup", setLeftButtonState);
document.body.addEventListener("mousemove", setLeftButtonState);

export const imShow = (image:Mat, text?:string)=>{
    staticDebugView?.imShow(image, text);
}

export const imShowOverlay = (background:Mat, overlay:Mat, text?:string)=>{
    const image = background.clone();
    overlayMask(overlay, image);
    staticDebugView?.imShow(image, text);
    image.delete();
}

export const showDebugText = (text:string)=>{
    if (staticDebugView) {
        staticDebugView.debugText = text;
    }
}

const ResizeObserver = (window as any).ResizeObserver || Polyfill;

export enum ZoomFeatures {
    None,
    ZoomIn = 1 << 0,
    ZoomOut = 1 << 1,
    Pan = 1 << 2,
    SnapTo = 1 << 3,
    All = 0xff
}

const ZoomFeatures_default = ZoomFeatures.All & ~ZoomFeatures.Pan;

const SHADOWS_ENABLED = true;

//WHY is react requiring this. dependencies are a mess? media view seems to be the culprit
let _transformer:ReactZoomPanPinchRef|undefined;
let _media:CBARMediaView|undefined;
let _primaryContainer:HTMLDivElement|undefined;
let _zoomScale = 1.0;
let _scaleToFit = 2.0;

export const isTouchDevice = 'ontouchstart' in window || !!navigator.msMaxTouchPoints;

interface CBARViewProps {
    className?:string,
    onContextCreated:(context:CBARContext)=>void,
    toolMode?:CBARToolMode
    zoom?:ZoomFeatures
}

export function CBARView(props: CBARViewProps) {

    const [renderContext, setRenderContext] = useState<CBARRenderContext>();
    const [receiver, setReceiver] = useState<CBARReceiver>();

    const context = useMemo(()=>{
        if (renderContext && receiver) {
            MessageLog(`react-home-ar (0.4.2184) by cambrian\ncambrian.io`);
            return new CBARContext(renderContext, receiver)
        }
    }, [renderContext, receiver])

    const [mediaProperties, setMediaProperties] = useState<CBARMediaInfo>();

    const doRender = useRef<boolean>(false);

    const [scene, setScene] = useState<CBARScene>();

    const [contentRect, setContentRect] = useState<ClientRect|DOMRect>();
    const observer = React.useRef(new ResizeObserver((entries:any) => {
        if (mounted.current) {
            setContentRect(entries[0].contentRect);
            setNeedsRefresh(true);
        }
    }));

    const primaryContainer = createRef<HTMLDivElement>();
    const transformer = createRef<ReactZoomPanPinchRef>();
    const container = createRef<HTMLDivElement>();
    const canvas = createRef<HTMLCanvasElement>();
    const drawingOverlay = createRef<HTMLDivElement>();
    const isZooming = useRef(false);

    const media = useRef<CBARMediaView>();
    const debugView = createRef<CBARDebugView>();
    const raycaster = new THREE.Raycaster();
    const isVideo = media.current?.mode === CBARMode.Video;
    const pixelRatio = window.screen.availWidth / document.documentElement.clientWidth;

    const [directionalLight, setDirectionalLight] = useState<CBARDirectionalLight>();

    const camera = new THREE.PerspectiveCamera( 43.1, 1280 / 720, 0.1, 1000 );
    const sceneJS = new THREE.Scene();

    const [viewSize, setViewSize] = useState<[number, number]>();

    const screenshotExecutor = useRef<DeferredExecutor<string>>();
    const screenshotOptions = useRef<ImageExportOptions>();

    const toolMode = props.toolMode ? props.toolMode : CBARToolMode.None;

    const [needsRefresh, setNeedsRefresh] = useState(false);
    const mounted = useRef<boolean>();

    const refresh = useCallback(()=>{
        doRender.current = true;
    }, [doRender])

    const tick = useCallback((canvas:HTMLCanvasElement) => {
        if (!mounted.current || !canvas) return;

        requestAnimationFrame(()=>tick(canvas));

        if (!media.current || !scene || (!doRender.current && !screenshotExecutor.current)) {
            return;
        }

        scene.context.gl.resolution.set(scene.context.gl.renderer.domElement.width, scene.context.gl.renderer.domElement.height)
        media.current.update();

        scene.render();

        if (screenshotExecutor.current) {
            const options = screenshotOptions.current ? screenshotOptions.current : {format:'jpeg', quality:90.0};
            MessageLog(`Exporting ${options.format} image, dimensions ${canvas.width}x${canvas.height}`);
            const screenshot = canvas.toDataURL(`image/${options.format}`, options.quality);
            if (screenshot.length > 15000) {
                screenshotExecutor.current.resolve(screenshot)
            } else {
                screenshotExecutor.current.reject(new Error("Invalid screenshot"));
            }
            screenshotExecutor.current = undefined;
        }

        doRender.current = media.current.mode === CBARMode.Video;

    }, [mounted, media, scene, screenshotExecutor, screenshotOptions, requestAnimationFrame, doRender]);

    useEffect(() => {
        if (!mounted.current || !scene) return;
        scene.toolModeChanged(toolMode);
        setIsDrawMode(isDrawingTool(toolMode));
        setActivelyDrawing(false);
    }, [mounted, toolMode, scene]);

    useEffect(()=>{
        if (!mounted.current) return;
        if (needsRefresh) {
            doRender.current = true;
            setNeedsRefresh(false);
        }
    }, [mounted, needsRefresh]);

    useEffect(() => {
        if (!mounted.current) return;

        if (primaryContainer.current && canvas.current && container.current && viewSize) {
            const rect  = primaryContainer.current.getBoundingClientRect();
            container.current.style.width = `${rect.width}px`;
            container.current.style.height = `${rect.height}px`;
            setNeedsRefresh(true);
        }
    }, [mounted, container, primaryContainer, viewSize]);

    //hacks for dependency issue:
    useEffect(()=>{
        if (!mounted.current) return;

        if (transformer.current && !_transformer) {
            _transformer = transformer.current;
        }
        if (media.current && !_media) {
            _media = media.current;
        }
        if (primaryContainer.current && !_primaryContainer) {
            _primaryContainer = primaryContainer.current;
        }
    }, [transformer, media, primaryContainer, mounted])

    const zoom = props.zoom === undefined ? ZoomFeatures_default : props.zoom

    const canZoomIn = (zoom & ZoomFeatures.ZoomIn) > 0;
    const canZoomOut = (zoom & ZoomFeatures.ZoomOut) > 0;
    const canPan = (zoom & ZoomFeatures.Pan) > 0;
    const canSnapTo = (zoom & ZoomFeatures.SnapTo) > 0;
    const canZoom = canZoomIn || canZoomOut;

    const snapToZoom = canSnapTo ? 0.1 : 0.0;

    const minZoomScale = useMemo(()=>{
        return canZoomOut ? 1.0 : _scaleToFit;
    }, [props, _scaleToFit]);

    const maxZoomScale = useMemo(()=>{
        return canZoomIn ? 4.0 : _scaleToFit;
    }, [props, _scaleToFit]);

    const getZoomScale = useCallback(()=>{
        if (_transformer) {
            _zoomScale = _transformer.state.scale;
        }
        return _zoomScale
    }, [_zoomScale, _transformer])

    const setZoomScale = useCallback((context, scale:number)=>{
        if (!mounted.current) return;
        if (_transformer) {
            _zoomScale = Math.min(Math.max(minZoomScale, scale), maxZoomScale);
            _transformer.centerView(_zoomScale);
        }
    }, [transformer, _transformer, mounted, minZoomScale])

    const zoomScaleToFit = useCallback(()=>{
        return _scaleToFit
    }, [_scaleToFit])

    const setZoomState = useCallback((context, newState)=>{
        if (!mounted.current) return;
        switch (newState) {
            case ZoomState.ZoomedIn:
                setZoomScale(context, maxZoomScale);
                break;
            case ZoomState.ZoomedOut:
                setZoomScale(context, minZoomScale);
                break;
            case ZoomState.FitScreen:
                setZoomScale(context, _scaleToFit);
                break;
        }
    }, [context, setZoomScale, _scaleToFit, mounted, minZoomScale, maxZoomScale])

    const getZoomState = useCallback(()=>{
        const scale  = getZoomScale();
        if (Math.abs(scale - _scaleToFit) < snapToZoom) {
            return ZoomState.FitScreen;
        }
        return scale > _scaleToFit ? ZoomState.ZoomedIn : ZoomState.ZoomedOut;
    }, [getZoomScale])

    useEffect(()=>{
        if (viewSize) {
            window.setTimeout(()=>{
                setZoomState(context, ZoomState.FitScreen)
            }, 100);
        }
    }, [viewSize, context, setZoomState])

    useEffect(() => {
        if (!mounted.current) return;
        if (mediaProperties && contentRect) {
            const mediaAspectRatio = mediaProperties.width / mediaProperties.height;
            const viewAspectRatio = contentRect.width / contentRect.height;

            const width = (mediaAspectRatio > viewAspectRatio) ? contentRect.width : contentRect.height * mediaAspectRatio;
            const height = (mediaAspectRatio > viewAspectRatio) ? contentRect.width / mediaAspectRatio : contentRect.height;

            _scaleToFit = Math.max(mediaAspectRatio / viewAspectRatio, viewAspectRatio / mediaAspectRatio);
            // console.log("scaleToFit", _scaleToFit);

            setViewSize([width, height]);
            setNeedsRefresh(true);
        }
    }, [mounted, mediaProperties, contentRect]);

    useEffect(() => {
        if (!mounted.current) return;
        if (context && viewSize) {
            context.gl.renderer.setSize(viewSize[0], viewSize[1]);
            const maxDimension = Math.max(viewSize[0], viewSize[1]);
            const _pixelRatio = isVideo ? 1.0 : pixelRatio * window.devicePixelRatio * _zoomScale;
            const maxTextureDimension = Math.min(4096, context.gl.renderer.capabilities.maxTextureSize / 4);
            const factor = Math.min(maxTextureDimension / (_pixelRatio * maxDimension), 1.0);
            //console.log("maxTextureDimensions", maxTextureDimensions);
            context.gl.renderer.setPixelRatio(_pixelRatio * factor);
            //console.log(`Rendering at ${viewSize[0]}x${viewSize[1]} at density ${pixelRatio}`)
        }
    }, [mounted, context, viewSize, isVideo]);

    useEffect(() => {
        if (primaryContainer.current) {
            observer.current.observe(primaryContainer.current);
        }
        return () => {
            if (observer.current && primaryContainer.current) {
                observer.current.unobserve(primaryContainer.current);
            }
        };
    }, [primaryContainer, observer]);

    const startVideoCamera = useCallback((context, tracking, facing) => {
        const _media = media.current!;
        if (scene) {
            return _media.startVideoCamera(context,tracking, facing);
        } else {
            return new Promise<void>((resolve,reject)=>{
                setSceneResolver({
                    resolve:() => {
                        _media.startVideoCamera(context, tracking, facing).then(()=>{
                            resolve();
                        }).catch(error=>{
                            reject(error);
                        });
                    },
                    reject
                })
                setScene(new CBARScene(context));
            })
        }
    }, [media, scene]);

    const stopVideoCamera = useCallback(() => {
        return media.current!.stopVideoCamera();
    }, [media]);

    const captureImage = useCallback(() => {
        return media.current!.captureImage()
    }, [media]);

    const captureScreenshot = useCallback((context:CBARContext, options?:ImageExportOptions) => {
        return new Promise<string>((resolve, reject) => {
            screenshotOptions.current = options;
            screenshotExecutor.current = {resolve, reject};
            setNeedsRefresh(true);
        });
    }, [screenshotExecutor, screenshotOptions, doRender]);

    const loadImage = useCallback((context:CBARContext, image:HTMLImageElement) => {
        if (media.current) {
            media.current.loadImage(image)
        }
    }, [media.current]);

    const raycast = useCallback((normalizedPoint:THREE.Vector2, searchObjects?:THREE.Object3D[])=>{

        const intersections: CBARIntersection[] = [];

        if (!context || !scene) return intersections;

        raycaster.setFromCamera(normalizedToScreen(normalizedPoint), context.gl.camera);

        if (!searchObjects) {
            searchObjects = scene.getEventObjects(CBAREventType.TouchDown);
        }

        const seen = new Set<string>();

        const intersects = raycaster
            .intersectObjects(searchObjects, true)
            .filter(item => {
                const id = makeId(item);
                if (seen.has(id)) return false;
                seen.add(id);
                return true
            });

        for (const intersect of intersects) {
            let eventObject: THREE.Object3D = intersect.object;

            let cbarObject: CBARObject3D<any>|undefined;
            while (eventObject.parent) {
                let obj = (eventObject as any).__cbarObject as CBARObject3D<any>|undefined;

                if (obj && obj.rootObject().receivesEvents && (obj.rootObject().existsAtPoint(normalizedPoint))) {
                    cbarObject = obj.rootObject();
                    break
                }

                eventObject = eventObject.parent
            }

            if (cbarObject) {
                //console.log("event: " + cbarObject.rootObject().name);
                intersections.push({
                    intersection:intersect,
                    object:cbarObject
                })
            }
        }

        return intersections;

    }, [context, scene, raycaster])

    useEffect(()=>{
        if (directionalLight && scene && camera && directionalLight.position.length() === 0.0) {
            const direction = new THREE.Vector3(3, 10, -5);
            directionalLight.position = direction.project(camera);
        }
    }, [directionalLight, scene]);

    useEffect(() => {

        if (scene && context) {
            if (scene.backgroundImage) {
                loadImage(context, scene.backgroundImage.image)
            }

            // //minimum level
            new CBARAmbientLight(context).load(undefined, {
                "intensity": 1.0,
                "color": "0xffffff"
            }).then((asset)=>{
                scene.assets.add(asset);
            });

            new CBARDirectionalLight(context).load(undefined, {
                "intensity": 2.5,
                "color": "0xffffff",
                "position": [0,0,0]
            }).then((asset)=>{
                asset.light.castShadow = SHADOWS_ENABLED;
                asset.light.shadow.camera = new OrthographicCamera(-3,3,3,-3, 0.1, 1000);
                asset.light.shadow.mapSize = new THREE.Vector2(2048,2048);
                asset.light.target.position.set(3,10,-20);
                scene.assets.add(asset);
                setDirectionalLight(asset);
            });
        }

    }, [scene, context, loadImage]);

    const [sceneResolver, setSceneResolver] = useState<DeferredExecutor<CBARScene>>();

    const loadSceneData = useCallback((context:CBARContext, json:CBARSceneProperties, surfaceTypes?:CBARSurfaceType[], room?:string|null|undefined, subroom?:string|null|undefined) => {

        return new Promise<CBARScene>((resolve, reject) => {
            if (!context) {
                reject(new Error("No context supplied"));
                return
            }
            new CBARScene(context).load(undefined, json, surfaceTypes, room, subroom).then(s=>{
                setScene(s);
                setSceneResolver({resolve, reject})
            }).catch(error=>{
                reject(error)
            })
        })

    }, []);

    const loadSceneAtPath = useCallback((context:CBARContext, dataPath:string, surfaceTypes?:CBARSurfaceType[]) => {

        let path = dataPath.split("/").slice(0,-1).join("/");

        return new Promise<CBARScene>((resolve, reject) => {
            if (!context) {
                reject(new Error("No context supplied"));
                return
            }
            fetch(dataPath).then((resp) => {
                resp.json().then((json: any) => {
                    new CBARScene(context).load(path, json, surfaceTypes).then(s=>{
                        if (!mounted.current) return;
                        setScene(s);
                        setSceneResolver({resolve, reject})
                    }).catch(error=>{
                        reject(error)
                    })
                }).catch((error)=>{
                    reject(new Error(`Invalid json data or path: ${dataPath}\n${error.message}`))
                })
            })
        })

    }, [mounted]);

    const onContextCreated = props.onContextCreated;
    const mediaReady = useCallback((view:CBARMediaView) => {
        if (context) {
            media.current = view;
            context.gl.scene.background = view.canvasTexture;
            onContextCreated(context)
        }

    }, [context, media, onContextCreated, debugView, mounted]);

    useEffect(()=>{
        if (debugView.current && !staticDebugView) {
            staticDebugView = debugView.current;
        }
    }, [debugView]);

    const mediaPropertiesChanged = useCallback((view:CBARMediaView, props:CBARMediaInfo) => {
        if (scene) {
            scene.cameraProperties.setMediaProperties(props)
        }
        setMediaProperties(props);
    }, [scene]);

    useEffect(() => {
        if (!mounted.current) return;
        if (sceneResolver && scene && canvas.current) {
            //finish any setup:
            scene.sceneLoaded()
            sceneResolver.resolve(scene);
            setSceneResolver(undefined);
            setZoomState(context, ZoomState.FitScreen);
            tick(canvas.current);
        }
    }, [sceneResolver, scene, tick, canvas, mounted, context]);

    const getMode = useCallback(() => {
        if (media.current) {
            return media.current.mode
        }
        return CBARMode.None
    }, [media]);

    //TODO:dependencies broken somehow, explaining weirdness here
    const _toolMode = useRef(CBARToolMode.None);
    useEffect(()=>{
        _toolMode.current = toolMode;
    }, [toolMode, _toolMode]);

    const getToolMode = useCallback(() => {
        return _toolMode.current;
    }, [_toolMode]);

    const makeId = useCallback((event: THREE.Intersection) => {
        return event.object.uuid + '/' + event.index
    }, []);

    const containerZIndex = useRef<string>();
    const overlayZIndex = useRef<string>();

    const [isDrawMode, setIsDrawMode] = useState(false);
    const [isActivelyDrawing, setActivelyDrawing] = useState(false);
    const [isOffscreen, setIsOffScreen] = useState(false);
    const [isOffCanvas, setIsOffCanvas] = useState(false);

    const sendTo = useCallback((front:boolean) => {
        if (!primaryContainer.current || !drawingOverlay.current) return;

        if (front) {
            if (!containerZIndex.current && primaryContainer.current.style.zIndex !== "unset") containerZIndex.current = primaryContainer.current.style.zIndex;
            if (!overlayZIndex.current && drawingOverlay.current.style.zIndex !== "unset") overlayZIndex.current = drawingOverlay.current.style.zIndex;

            primaryContainer.current.style.zIndex = `${INT_MAX-10}`;
            drawingOverlay.current.style.zIndex = `${INT_MAX}`;
        } else if (containerZIndex.current || overlayZIndex.current) {
            primaryContainer.current.style.zIndex = containerZIndex.current ? containerZIndex.current : "unset";
            drawingOverlay.current.style.zIndex = overlayZIndex.current ? overlayZIndex.current : "unset";
        }

        //console.log("Send to", front ? "FRONT" : "BACK", primaryContainer.current.style.zIndex);

    }, [primaryContainer, drawingOverlay, containerZIndex, overlayZIndex]);

    const onDeviceEvent = useCallback((e:React.MouseEvent|React.TouchEvent, eventType:CBAREventType, position:THREE.Vector2) => {

        if (scene && context && container.current && mounted.current) {
            const rect = (e.target as HTMLElement).getBoundingClientRect();
            const { left, right, top, bottom } = rect;

            const normalizedPoint = new THREE.Vector2((position.x - left) / (right - left), (position.y - top) / (bottom - top));
            const searchObjects = scene.getEventObjects(eventType);

            const intersections = raycast(normalizedPoint, searchObjects);

            const event:CBARMouseEvent = {
                type:eventType,
                context:context,
                point:normalizedPoint,
                toolMode:toolMode,
                scene,
                mouseEvent:e,
                intersections:intersections
            };
            scene.handleEvent(event);

            if (isDrawMode) {
                if (!isTouchDevice) {
                    setActivelyDrawing(leftMouseButtonOnlyDown);
                }

                if (drawingOverlay.current && container.current && transformer.current) {
                    const parentSize = container.current.getBoundingClientRect();
                    const radius = DRAW_RADIUS / transformer.current.state.scale;
                    const radiusPixels = Math.ceil(radius * parentSize.width);
                    const diameterPixels = Math.ceil(2 * radius * parentSize.width);

                    drawingOverlay.current.style.width = `${diameterPixels}px`;
                    drawingOverlay.current.style.height = `${diameterPixels}px`;

                    drawingOverlay.current.style.left = `${position.x - radiusPixels}px`;
                    drawingOverlay.current.style.top = `${position.y - radiusPixels}px`;

                    drawingOverlay.current.style.borderRadius = `50%`
                }

                if (eventType === CBAREventType.TouchDown) {
                    setActivelyDrawing(true);
                    sendTo(true);
                    setIsOffCanvas(false);
                } else if (eventType == CBAREventType.TouchUp) {
                    setActivelyDrawing(false);
                    sendTo(false);
                } else if (eventType === CBAREventType.TouchLeave) {
                    const buffer = 0.05;
                    if (normalizedPoint.x < buffer && normalizedPoint.x > (1 - buffer) && normalizedPoint.y < buffer && normalizedPoint.y > (1 - buffer)) {
                        setIsOffScreen(true);
                    }
                    setIsOffCanvas(true);
                } else if (eventType === CBAREventType.TouchMove) {
                    setIsOffScreen(false);
                    setIsOffCanvas(false);
                }
            }

        }
    }, [scene, context, raycast, toolMode, container, drawingOverlay, isDrawMode, isOffscreen, sendTo, isTouchDevice, transformer, mounted]);

    useEffect(()=>{
        if (!mounted.current) return;
        if (isActivelyDrawing) {
            setIsOffScreen(false);
        } else {
            sendTo(false);
        }
        //console.log("Is actively drawing", isActivelyDrawing);
    }, [isActivelyDrawing, sendTo, mounted])

    useEffect(()=>{
        if (isOffscreen) {
            sendTo(false);
        }
        //console.log("Is offscreen", isOffscreen);
    },[isOffscreen, sendTo])

    useEffect(()=>{

        if (drawingOverlay.current && container.current) {
            const show = isDrawMode && !isOffscreen && !isOffCanvas;
            container.current.style.cursor = show ? "none" : "default";
            drawingOverlay.current.style.visibility = show ? "visible" : "hidden";
        }

    }, [container, drawingOverlay, isDrawMode, isOffscreen, isOffCanvas])

    const onMouseEvent = useCallback((e:React.MouseEvent, eventType:CBAREventType) => {
        onDeviceEvent(e, eventType, new THREE.Vector2(e.clientX, e.clientY));
    }, [onDeviceEvent])

    const lastPosition = useRef<THREE.Vector2>();
    const onTouchEvent = useCallback((e:React.TouchEvent, eventType:CBAREventType) => {
        if (isZooming.current) return;

        const pos = lastPosition.current;
        if (e.touches.length === 1) {
            const position = new THREE.Vector2(e.touches[0].clientX, e.touches[0].clientY);
            onDeviceEvent(e, eventType, position);
            lastPosition.current = position;
        } else if (pos) {
            onDeviceEvent(e, eventType, pos);
            if (eventType === CBAREventType.TouchUp || eventType === CBAREventType.TouchLeave) {
                lastPosition.current = undefined;
            }
        }
    }, [onDeviceEvent, lastPosition, isZooming])


    const autoRenderHandle = useRef(0);

    const initialize = useCallback(() => {
        if (!container.current || !canvas.current) return;

        const renderer = new THREE.WebGLRenderer({canvas:canvas.current});
        renderer.autoClearColor = false;
        renderer.sortObjects = false;
        renderer.autoClear = false;

        const width = container.current.clientWidth * pixelRatio;
        const height = container.current.clientHeight * pixelRatio;

        renderer.physicallyCorrectLights = true;
        renderer.shadowMap.enabled = true;
        renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        renderer.shadowMap.autoUpdate = true;

        //depth buffer
        const target = new THREE.WebGLRenderTarget(width, height);
        target.texture.format = THREE.RGBFormat;
        //target.texture.minFilter = THREE.NearestFilter;
        //target.texture.magFilter = THREE.NearestFilter;
        //target.texture.generateMipmaps = false;

        target.stencilBuffer = false;
        target.depthBuffer = false;
        target.depthTexture = new THREE.DepthTexture(width, height);
        target.depthTexture.format = THREE.DepthFormat;
        target.depthTexture.type = THREE.UnsignedShortType;

        if (media.current?.canvasTexture?.image) {
            const mediaCanvas =  media.current.canvasTexture.image as HTMLCanvasElement;
            canvas.current.width = mediaCanvas.width;
            canvas.current.height = mediaCanvas.height;
        }

        const renderWidth = isVideo ? container.current.clientWidth : width;
        const renderHeight = isVideo ? container.current.clientHeight : height;

        renderer.setSize(renderWidth, renderHeight);
        setRenderContext(new CBARRenderContext(camera, sceneJS, renderer, target, new THREE.Vector2(renderWidth, renderHeight)));

        autoRenderHandle.current = window.setInterval(()=>{
            doRender.current = true;
        }, 2000);

    }, [container, canvas, camera, sceneJS, doRender, isVideo, media, autoRenderHandle]);

    const initializeRef = useRef(initialize);
    useEffect(() => {
        initializeRef.current = initialize;
    }, [initialize]);

    useEffect(() => {
        if (initializeRef.current) {
            initializeRef.current()
        }
    }, [initializeRef]);

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
            media.current = undefined;
            if (autoRenderHandle.current) {
                window.clearInterval(autoRenderHandle.current);
            }
            _transformer = undefined;
            _media = undefined;
            _primaryContainer = undefined;
        }
    }, []);

    const refScene = useRef<CBARScene>();

    const getScene = useCallback(()=>{
        return scene ? scene : refScene.current;
    }, [scene, refScene])

    useEffect(()=>{
        refScene.current = scene;
    },[scene])

    useMemo(()=>{
        if (!receiver) {
            setReceiver({loadSceneAtPath, loadSceneData, startVideoCamera, stopVideoCamera, captureImage, captureScreenshot,
                loadImage, refresh, getMode, getToolMode, getScene, getZoomScale, setZoomScale, zoomScaleToFit, getZoomState, setZoomState})
        }
    }, [receiver, loadSceneAtPath, loadSceneData, startVideoCamera, captureImage, captureScreenshot, loadImage, refresh, getMode, getToolMode, getScene, getZoomState, setZoomState]);

    const touchEvents = useMemo(()=>{
        return isTouchDevice ? {
            onTouchStart:(e:React.TouchEvent)=>onTouchEvent(e, CBAREventType.TouchDown),
            onTouchEnd:(e:React.TouchEvent)=>onTouchEvent(e, CBAREventType.TouchUp),
            onTouchMove:(e:React.TouchEvent)=>onTouchEvent(e, CBAREventType.TouchMove),
            onTouchCancel:(e:React.TouchEvent)=>onTouchEvent(e, CBAREventType.TouchLeave)
        } : {
            onContextMenu:(e:React.MouseEvent)=>onMouseEvent(e, CBAREventType.ContextMenu),
            onWheel:(e:React.MouseEvent)=>onMouseEvent(e, CBAREventType.Wheel),
            onPointerDown:(e:React.MouseEvent)=>onMouseEvent(e, CBAREventType.TouchDown),
            onPointerUp:(e:React.MouseEvent)=>onMouseEvent(e, CBAREventType.TouchUp),
            onPointerLeave:(e:React.MouseEvent)=>onMouseEvent(e, CBAREventType.TouchLeave),
            onPointerMove:(e:React.MouseEvent)=>onMouseEvent(e, CBAREventType.TouchMove)
        }
    }, [onTouchEvent])

    return (
        <div ref={primaryContainer} className={props.className} style={{width:"100%", height:"100%", position:"relative"}}>
            <div ref={drawingOverlay} style={{visibility:"hidden", pointerEvents: "none", userSelect:"none",
                position: "fixed", zIndex:1, top:0, left:0, backgroundColor: "#ffa", borderRadius:"50%", display:"inline-block"}} />

            <CBARRenderer toolMode={toolMode} container={container} transformer={transformer} canvas={canvas}
                          touchEvents={touchEvents} minZoomScale={minZoomScale} maxZoomScale={maxZoomScale}
                          isVideo={isVideo} isDrawMode={isDrawMode} canZoom={canZoom} canPan={canPan} />

            <CBARDebugView ref={debugView} style={{position: "fixed", zIndex:INT_MAX+1, top:0, right:0,
                maxWidth:"50%", maxHeight:"50%", minWidth:"25%", imageRendering:"pixelated"}} />

            <CBARMediaView onMediaReady={mediaReady} onMediaUpdated={mediaPropertiesChanged} />
        </div>
    )
}

interface CBARRendererProps {
    toolMode:CBARToolMode,
    container:React.Ref<HTMLDivElement>,
    transformer:React.Ref<ReactZoomPanPinchRef>,
    canvas:React.Ref<HTMLCanvasElement>,
    touchEvents:any
    minZoomScale:number
    maxZoomScale:number
    isVideo:boolean
    isDrawMode:boolean
    canZoom:boolean
    canPan:boolean
}

const CBARRenderer = React.memo<CBARRendererProps>((props) => {
    const isZooming = useRef(false);

    return (
        <div ref={props.container}>
            <TransformWrapper ref={props.transformer} onZoomStart={()=>isZooming.current = true}
                              onZoomStop={()=>window.setTimeout(()=>{ isZooming.current = false}, 500)}
                              disabled={props.isVideo || props.toolMode !== CBARToolMode.None || props.isDrawMode || !props.canZoom}
                              wheel={{step:0.05}} pinch={{step:0.03}} minScale={props.minZoomScale} maxScale={props.maxZoomScale} panning={{disabled:!props.canPan}}>
                <TransformComponent>
                    <canvas ref={props.canvas} style={{background:"#000", width:"fit-content", height:"fit-content"}} {...props.touchEvents} />
                </TransformComponent>
            </TransformWrapper>
        </div>
    )
})

