import {ColorConfig, DetailsConfig} from "cambrian-base";
import {ProductItem} from "./ProductItem";
import {SwatchItem} from "./SwatchItem";
import {Product} from "./Product";
import {ProductBrand} from "./ProductBrand";
import {ProductCollection} from "./ProductCollection";
import {DataItem} from "./DataItem";
import {ProductTexture} from "./ProductTexture";

export class ProductColor extends ProductItem implements SwatchItem, ProductItem {

    constructor(dataUrl?:string) {
        super(dataUrl);
        this.description = "color";
    }

    public product!: Product;

    public get ppi():number|undefined {
        return this._ppi ? this._ppi : this.product.ppi
    }

    public set ppi(value:number|undefined) {
        this._ppi = value
    }

    public get scale():number|undefined {
        return this._scale ? this._scale : this.product.scale
    }

    public set scale(value:number|undefined) {
        this._scale = value
    }

    public get brand(): ProductBrand {
        return this.product.brand
    }

    public get collection(): ProductCollection {
        return this.product.collection
    }

    public get parent():DataItem|undefined {
        return this.product
    }

    public get textures():ProductTexture[] {
        return this._textures.length ? this._textures : this.product.textures
    }

    public get details():DetailsConfig | undefined {
        return this._details ? this._details : this.product.details
    }

    public get children(): DataItem[] {
        return this._textures
    }

    public load(json:ColorConfig) {
        super.load(json);
    }
}