export function getEnumKeys(en:any) : string[] {
    const keys = Object.keys(en);
    const numericKeys = keys.filter(key => !isNaN(Number(en[key])));
    if (numericKeys.length) {
        return numericKeys
    } else {
        return keys
    }
}

export function getEnumValues<T>(en:any) : T[] {
    const keys = getEnumKeys(en);
    const values:any[] = [];
    keys.forEach(key=>values.push(en[key]));
    return values
}