import {CBAREventHandler, CBARMode, CBARRenderContext, CBARSurfaceType, CBARToolMode} from "./CBARTypes";
import {CBARScene, CBARSceneProperties} from "./components/CBARScene";
import {CBARDebugView} from "./internal/CBARDebugView";

export type ImageExportOptions = {
    format:'jpeg'|'png'
    quality?:number
}

export enum CBARFeatureTracking {
    None="none",
    World="world",
    Face="face",
    Card="card",
    Page="page",
    Classifier="classifier",
}

/** https://developer.mozilla.org/en-US/docs/Web/API/MediaTrackConstraints/facingMode **/
export enum CBARCameraFacing {
    Default='default',
    Environment='environment',
    User='user'
}

export enum CBARCameraResolution {
    Default = 1280,
    Low = 640,
    HighDef = 2160,
    Maximum = 4096
}

export enum CBARDebug {
    None = 0,
    OpticalFlow = 1 << 0,
    HoughLines = 1 << 1,
    TrackedLines = 1 << 2,
    ColorSegmentation = 1 << 3,
    All = ~(~0 << 4)
}

export enum ZoomState {
    ZoomedIn="zoom-in",
    ZoomedOut="zoom-out",
    FitScreen="fit-screen"
}

export interface CBARReceiver {
    loadSceneAtPath:(context:CBARContext, jsonPath:string, surfaceTypes?:CBARSurfaceType[])=>Promise<CBARScene>
    loadSceneData:(context:CBARContext, json:CBARSceneProperties, surfaceTypes?:CBARSurfaceType[], room?:string|null|undefined, subroom?:string|null|undefined)=>Promise<CBARScene>
    startVideoCamera:(context:CBARContext, tracking:CBARFeatureTracking, facing:CBARCameraFacing)=>Promise<void>
    stopVideoCamera:(context:CBARContext)=>void
    captureImage:(context:CBARContext) => Promise<HTMLImageElement>
    captureScreenshot:(context:CBARContext, format?:ImageExportOptions) => Promise<string>
    loadImage:(context:CBARContext, image:HTMLImageElement) => void
    refresh:(context:CBARContext) => void
    getMode:(context:CBARContext)=>CBARMode
    getToolMode:(context:CBARContext)=>CBARToolMode
    getScene:(context:CBARContext)=>CBARScene|undefined

    getZoomScale:(context:CBARContext)=>number
    setZoomScale:(context:CBARContext, value:number)=>void
    zoomScaleToFit:(context:CBARContext)=>number

    getZoomState:(context:CBARContext)=>ZoomState
    setZoomState:(context:CBARContext, state:ZoomState)=>void
}

export function isDrawingTool(mode:CBARToolMode) {
    return mode === CBARToolMode.DrawSurface || mode === CBARToolMode.EraseSurface
}

export class CBARContext {

    constructor(public gl:CBARRenderContext, private _receiver:CBARReceiver) {

    }

    private _handler?:CBAREventHandler; //multiple causing issues

    public setHandler(handler:CBAREventHandler) {
        this._handler = handler
    }

    public getHandlers() {
        return this._handler ? [this._handler] : []
    }

    public captureImage() {
        return this._receiver.captureImage(this)
    }

    public captureScreenshot(format?:ImageExportOptions) {
        return this._receiver.captureScreenshot(this, format);
    }

    public loadImage(image: HTMLImageElement) {
        return this._receiver.loadImage(this, image)
    }

    public refresh() {
        return this._receiver.refresh(this)
    }

    public loadSceneAtPath(jsonPath: string, surfaceTypes?:CBARSurfaceType[]) {
        return this._receiver.loadSceneAtPath(this, jsonPath, surfaceTypes)
    }

    public loadSceneData(json: CBARSceneProperties, surfaceTypes?:CBARSurfaceType[], room?:string|null|undefined, subroom?:string|null|undefined) {
        return this._receiver.loadSceneData(this, json, surfaceTypes, room, subroom)
    }

    public get scene() {
        return this._receiver.getScene(this)
    }

    public get mode() {
        return this._receiver.getMode(this)
    }

    public get toolMode() {
        return this._receiver.getToolMode(this)
    }

    public get isDrawing() {
        return isDrawingTool(this.toolMode)
    }

    public startVideoCamera(tracking = CBARFeatureTracking.None, facing=CBARCameraFacing.Environment) {
        return this._receiver.startVideoCamera(this, tracking, facing);
    }

    public stopVideoCamera() {
        return this._receiver.stopVideoCamera(this);
    }

    public get zoomScale() {
        return this._receiver.getZoomScale(this);
    }

    public set zoomScale(value) {
        this._receiver.setZoomScale(this, value);
    }

    public get zoomScaleToFit() {
        return this._receiver.zoomScaleToFit(this);
    }

    public get zoomState() {
        return this._receiver.getZoomState(this);
    }

    public set zoomState(state:ZoomState) {
        this._receiver.setZoomState(this, state);
    }
}