import * as THREE from "three";
import {CBARLightAsset, CBARLightAssetProperties} from "./CBARLightAsset";
import {CBARContext} from "../../CBARContext";
import {CBARVector} from "../../CBARTypes";

interface CBARRectAreaLightProperties extends CBARLightAssetProperties {
    target?:CBARVector
    width?:number
    height?:number
}

export class CBARRectAreaLight extends CBARLightAsset {

    constructor(context:CBARContext) {
        super(context);

        this._light.castShadow = true;
        this.setRenderObject(this.light)
    }

    protected _light = new THREE.RectAreaLight();

    public get light():THREE.RectAreaLight {
        return this._light
    }

    load(basePath:string|undefined, json:CBARRectAreaLightProperties) : Promise<CBARRectAreaLight> {

        return new Promise<CBARRectAreaLight>((resolve) => {
            return super.load(basePath, json).then(()=>{
                if (json.target) {
                    this._light.lookAt(json.target[0], json.target[1], json.target[2])
                }

                if (json.width) {
                    this._light.width = json.width
                }

                if (json.height) {
                    this._light.height = json.height
                }

                resolve(this)
            })
        })
    }

    public data() : any {
        const data:any = super.data();

        return data
    }

    get description() : string {
        return "Area light"
    }
}