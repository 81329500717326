import {CBARObject} from "./CBARObject";
import {CBARError, CBARErrorCode} from "../CBARError";
import {CBARContext} from "../CBARContext";
import {ImageCrop} from "cambrian-base";
import {VerboseLog} from "../internal/GlobalLogger";

const getCropValue = (value:string, ofValue:number):number => {
    let isPct = value.endsWith("%");
    let num = Number(value.replace(/\D/g,''));

    if (isPct) {
        return ofValue * num / 100.0;
    }
    return num;
}

export class CBARImage extends CBARObject<CBARImage> {

    public constructor(context:CBARContext, public name?:string) {
        super(context);
        this.crossOrigin = "anonymous"
    }

    public image = new Image();
    public path?:string;
    public relativePath?:string;
    public isJPEG = false;

    load(basePath:string|undefined, path:string, crop?:ImageCrop) {
        this.path = path;

        return new Promise<CBARImage>((resolve,reject) => {

            if (!path) {
                this.rejectPromise(reject, new CBARError(`Image path cannot be null`, CBARErrorCode.NullReference));
                return
            }

            if (typeof (path as any) !== 'string') {
                this.rejectPromise(reject, new CBARError(`path is not a string, type: ${typeof path}`, CBARErrorCode.InvalidType, path));
                return
            }

            const fullPath = path.startsWith("http") || path.startsWith("blob") || path.startsWith("dataUrl") ? path : basePath ? `${basePath}/${path}` : path;

            this.image.onload = () => {
                if (crop) {
                    const outputCanvas = document.createElement('canvas');
                    const width = this.image.width;
                    const height = this.image.height;
                    const left = getCropValue(crop.left, width);
                    const right = getCropValue(crop.right, width);
                    const top = getCropValue(crop.top, height);
                    const bottom = getCropValue(crop.bottom, height);
                    outputCanvas.width =  width - right - left;
                    outputCanvas.height = height - bottom - top;
                    const ctx = outputCanvas.getContext("2d");
                    if (ctx) {
                        ctx.drawImage(this.image, left, top, outputCanvas.width, outputCanvas.height, 0,  0, outputCanvas.width, outputCanvas.height);
                        this.image.onload = () => {
                            VerboseLog("Loaded cropped image at " + this.image.src);
                            resolve(this);
                        };
                        this.image.src = outputCanvas.toDataURL("image/png")
                    }
                } else {
                    VerboseLog("Loaded image at " + this.image.src);
                    resolve(this);
                }
            };
            this.image.onerror = () => {
                this.rejectPromise(reject, new CBARError(`Image ${this.image.src} not found`, CBARErrorCode.ImageNotFoundAtPath, this));
            };

            this.relativePath = path;
            this.image.src = fullPath;
            this.isJPEG = path.search(/\.jpe?g($|\?)/i) > 0 || path.search(/^data:image\/jpeg/) === 0
        })
    }

    public get crossOrigin() {
        return this.image.crossOrigin
    }

    public set crossOrigin(value) {
        this.image.crossOrigin = value
    }

    public get width() {
        return this.image.width
    }

    public get height() {
        return this.image.height
    }

    public get area() {
        return this.width * this.height
    }

    public data() : any {
        return this.path
    }

    get description() : string {
        return "Image"
    }

    unload() {

    }
}

