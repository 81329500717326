import * as THREE from "three";
import {CBARTexture} from "./CBARTexture";
import {Texture} from "three";
import {CBARStandardMaterial} from "./CBARStandardMaterial";
import {CBARMaterialProperty, CBARTextureType} from "./CBARMaterial";

enum _CBARPhysicalTextureType {
    clearcoat="clearcoat"
}

//https://github.com/microsoft/TypeScript/issues/17592
type CBARPhysicalTextureType = CBARTextureType | _CBARPhysicalTextureType;
const CBARPhysicalTextureType = {...CBARTextureType, ..._CBARPhysicalTextureType};

export enum _CBARPhysicalMaterialProperty {
    reflectivityValue = "reflectivityValue",

    clearcoatValue = "clearcoatValue",
    clearcoatRoughnessValue = "clearcoatRoughnessValue",
    clearcoatNormalScale =  "clearcoatNormalScale",

    sheenColor = "sheenColor"
}

type CBARPhysicalMaterialProperty = CBARMaterialProperty | _CBARPhysicalMaterialProperty;
const CBARPhysicalMaterialProperty = {...CBARMaterialProperty, ..._CBARPhysicalMaterialProperty};

export class CBARPhysicalMaterial extends CBARStandardMaterial {

    private _physicalMaterial:THREE.MeshPhysicalMaterial = new THREE.MeshPhysicalMaterial({transparent: true});

    protected setTextureMap(texture:CBARTexture) : Texture | null {
        if (!texture.threeTexture) return null;

        const type = texture.type as CBARPhysicalTextureType;

        if (type === CBARPhysicalTextureType.clearcoat) {
            this.threeMaterial.clearcoatNormalMap = texture.threeTexture;
            return this.threeMaterial.clearcoatNormalMap
        }

        return super.setTextureMap(texture)
    }

    public get threeMaterial() {
        return this._physicalMaterial
    }

    public set threeMaterial(value) {
        this._physicalMaterial = value
    }

    public setMaterialProperty(name:CBARMaterialProperty, value:any) {

        switch (name as CBARPhysicalMaterialProperty) {
            case CBARPhysicalMaterialProperty.reflectivityValue:
                this.threeMaterial.reflectivity = value;
                break;
            case CBARPhysicalMaterialProperty.clearcoatValue:
                this.threeMaterial.clearcoat = value;
                break;
            case CBARPhysicalMaterialProperty.clearcoatRoughnessValue:
                this.threeMaterial.clearcoatRoughness = value;
                break;
            case CBARPhysicalMaterialProperty.clearcoatNormalScale:
                this.threeMaterial.clearcoatNormalScale = new THREE.Vector2(value[0], value[1]);
                break;
            case CBARPhysicalMaterialProperty.sheenColor:
                this.threeMaterial.sheen = new THREE.Color(parseInt(value, 16));
                break
        }

        return super.setMaterialProperty(name, value)
    }

    get description() : string {
        return "Physical Material"
    }

    protected cloneObject() : CBARPhysicalMaterial {
        return new CBARPhysicalMaterial(this.context, this)
    }
}