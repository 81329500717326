import {CBARObject3D} from "../components";
import {CBARStandardMaterial} from "../components/CBARStandardMaterial";
import * as THREE from "three";
import {CBARContext} from "../CBARContext";
import {CBARTiledAsset} from "./CBARTiledAsset";

export class CBARTile extends CBARObject3D<CBARTile> {

    public material: CBARStandardMaterial;
    public mesh: THREE.Mesh;
    public readonly tileThickness = 0.01;
    public geometry:THREE.BufferGeometry;

    constructor(public parent: CBARTiledAsset, context:CBARContext, material: CBARStandardMaterial) {
        super(context);

        this.material = material;
        this.material.threeMaterial.depthTest = false;

        this.geometry = new THREE.BoxBufferGeometry(1, 1, this.tileThickness);
        this.mesh = new THREE.Mesh(this.geometry, this.material.threeMaterial);
        this.mesh.castShadow = parent.castShadow;
        this.mesh.receiveShadow = parent.receiveShadow;

        const uvs = this.geometry.attributes.uv.array;
        this.geometry.setAttribute( 'uv2', new THREE.BufferAttribute( uvs, 2 ) );

        this.setRenderObject(this.mesh);
    }

    public get size() : THREE.Vector2 {
        return new THREE.Vector2(this.renderObject.scale.x, this.renderObject.scale.y)
    }

    public set size(value:THREE.Vector2) {
        this.renderObject.scale.set(value.x, value.y, this.renderObject.scale.z)
    }

    public get offset() : THREE.Vector2 {
        return new THREE.Vector2(this.mesh.position.x, this.renderObject.position.y)
    }

    public set offset(value:THREE.Vector2) {
        this.renderObject.position.set(value.x, value.y, this.renderObject.position.z)
    }

    private _rotation = 0;
    get rotation() : number {
        return this._rotation
    }

    set rotation(value:number) {
        this._rotation = value;
        this.renderObject.rotation.set(0, 0, this._rotation)
    }

    get description(): string {
        return "Tile"
    }

    public rootObject() : CBARObject3D<any> {
        return this.parent
    }
}
